<template>
  <div class="home wrapper">
    <Nav :toggleNav="showNav" />
    <aside class="sidebar">
      <HomeLayout @clicked="toggleMenuFunc" />
      <div class="sidebar-footer">
        Powered by <img src="@/assets/img/p360_logo.png" alt="" />
      </div>
    </aside>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Sidebar/TheNav.vue";
import HomeLayout from "@/views/Layouts/HomeLayout";

export default {
  name: "Home",
  components: {
    Nav,
    HomeLayout,
  },
  data() {
    return {
      toggleMenu: false,
      showNav: false,
    };
  },
  methods: {
    passToNav(value) {
      this.showNav = value;
    },
    toggleMenuFunc() {
      this.toggleMenu = !this.toggleMenu;
      this.showNav = this.toggleMenu;
    }
  },
  mounted(){
    
  }
};
</script>
