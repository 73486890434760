<template>
  <div    
    @click="ConversationSeleted(message)"
    class="chat-box"
    :class="{unread: !message.IsRead}"
  >
    <!-- If Image -->
    <div class="chat-box-profile avatar" v-if="type === 'img'">
      <img src="@/assets/img/user.png" alt="" />
    </div>
    <!-- If Icon -->
    <div
      class="chat-box-profile chat-box-profile-icon bg-success-light text-success"
      v-if="type === 'icon'"
    >
      <i class="fa" :class="icon"></i>
    </div>
    <div class="chat-box-details">
      <h5>{{ message.recepient_name }}</h5>
      <h6 v-html="message.Body"></h6>
    </div>
    <div class="chat-box-date-status">
      <h6>{{ message.created_ts | moment("MM/DD/YYYY") }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  props: { 
    type: {
      type: String,
    },
    message:{
        type: Object
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    ConversationSeleted(message) {
      console.log(message)
      this.$store.commit("setConversationDetails", message);
      let url = "/chat/all"
      console.log(url);
      this.$router.push(url);
    },
  },
};
</script>
