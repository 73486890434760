import { render, staticRenderFns } from "./CallBox.vue?vue&type=template&id=a9e32fbc&scoped=true&"
import script from "./CallBox.vue?vue&type=script&lang=js&"
export * from "./CallBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9e32fbc",
  null
  
)

export default component.exports