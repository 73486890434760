<template>
  <!-- Content Begin -->
  <main class="theme-main theme-main-full">
    <div class="theme-main-body">
        <button type="button" onclick="window.history.back()" class="btn-light btn-icon mb-3">
            <feather type="chevron-left" size="18"></feather>
        </button>
        <div class="row">
            <div class="col-md-4 mb-sm-2 text-center">
                <div class="bg-white br-15 p-3">
                    <div class="avatar avatar-xl avatar-rounded text-center mb-1">
                        <img src="@/assets/img/user.png" alt="" />
                    </div>
                    <h4 class="text-info bold mb-2">{{ $route.query.user }}</h4>
                    <div class="row">
                        <div class="col-8 offset-2 col-md-12 offset-md-0">
                            <ul class="row">
                                <li class="col-6">
                                    <a href="#" class="sidebar-main-icon btn-icon btn-light-info">
                                        <feather type="message-square" size="18"></feather>
                                    </a>
                                    <h6 class="text-info">
                                        Message
                                        {{ log }}
                                    </h6>
                                </li>
                                <li class="col-6">
                                    <a href="#" class="sidebar-main-icon btn-icon btn-light-success" v-b-modal.modal-6>
                                        <feather type="phone" size="18"></feather>
                                    </a>
                                    <h6 class="text-success">Voice Call</h6>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <ul class="list list-lg mb-2">
                    <li class="bg-white br-15 p-2" v-for="n in 10" :key="n">
                        <ul class="list-flex list-flex-between">
                            <li>
                                <p class="bold text-gray-500"><feather type="phone-incoming" size="18"></feather> Incoming Call</p>
                            </li>
                            <li class="text-right">
                                <p class="font-sm">15 Minutes ago 5:10  (22/10/19)</p>
                            </li>
                        </ul>
                    </li>
                </ul>
                
                <button type="button" class="bg-white border-0 br-15 btn-block p-2 text-center text-danger">
                    <feather type="trash-2" size="15"></feather>
                    <span class="d-block bolder">Delete Call Log</span>
                </button>
            </div>
        </div>
    </div>
  </main>
  <!-- Content End -->
</template>

<script>
export default {
    components:{
    }
};
</script>

<style lang="scss" scoped>
.theme {
  &-main-full {
    .theme-main-body {
      height: 100vh;
    }
  }
}
</style>