<template>
    <div>
        <ul class="chat-main">
          <li v-for="contact in contacts" :key="contact.id">
            <CallBox :dataObj="contact" @initiateCall="toggleCall" />
          </li>
          <infinite-loading @infinite="infiniteContactHandler">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </ul>
    </div>
</template>
<script>
const  Twilio = require('twilio-client');
import axios from "axios";
import CallBox from "@/components/Call/CallBox.vue";
export default {
  data() {
    return {
      callToken:'',
      settings: {
        maxScrollbarLength: 60,
      },
      chatItems: [
        {
          avatar: "@/assets/img/user.png",
          name: "Josephin water",
          msg:
            "Hi, i am josephin. How are you.. ! There are many variations of passages.",
          date: "22/10/19",
          path: "/chat",
        },
      ],
      chatTab: 0,
      page: 1,
      limit: 10,

      
      loading: true,
      isDrawer: false,
      messages: [],
      filterMessages: [],
      conversations: false,
      showSearch: false,
      searchQuery: "",
      timeoutHandle: "",
      contacts: [],

      processing: false,
      form: {
        phone_number: "",
        physician_id: "",
        prescriber_id: "",
        asset: "",
        template_id: "",
        template: "",
        recipient: {
          id: "",
          name: "",
        },
        type: 0,
      },
      perscriberInactive: true,
      phoneNumberError: true,
      filterOthers: [],
    };
  },
  created() {
      this.$store.commit('initialize');
  },
  mounted(){
    console.log("Call Tab View");
  },
  methods: {
    toggleCall(e) {
      console.log(e.phone);

      let phone = e.phone;
      if(phone){
          // make outbound call with current number
         // var n = '+' + this.countryCode + this.currentNumber.replace(/\D/g, '');
         Twilio.Device.ready(function() {
              self.log = 'Connected';
            });
          console.log(Twilio.Device.connect({ number: phone}));
          this.log = 'Calling ' + phone;
      }
      else {
        // hang up call in progress
        Twilio.Device.disconnectAll();
      }
    },
  
    infiniteHandler($state) {
      var token = localStorage.getItem("access_token"),
        active_campaign_ID = localStorage.getItem("active_campaign_ID");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/conversations", {
          params: {
            Campaign_ID: active_campaign_ID,
            search_query: "",
            limit: this.limit,
            page: this.page,
          },
        })
        .then(({ data }) => {
          if (data.data.length) {
            if (data.data.length === this.limit) {
              this.page += 1;
            } else {
              $state.complete();
            }
            this.messages.push(...data.data);
            this.filterMessages = this.messages;
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    infiniteContactHandler($state) {

      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/call-logs", {
          params: {
            start: 1,
            search_query: "",
            limit: this.limit,
            page: this.page,
          },
        })
        .then(({ data }) => {
          if (data.data.length) {
            // if (data.data.length === this.limit) {
            //   this.page += 1;
            // } else {
            //   $state.complete();
            // }
            this.contacts.push(...data.data);
            // this.filterMessages = this.messages;
            // $state.loaded();
            $state.complete();
          } else {
            $state.complete();
          }
        });
    },
    getData() {
      window.clearTimeout(this.timeoutHandle);

      this.timeoutHandle = setTimeout(() => {
        var token = localStorage.getItem("access_token"),
          active_campaign_ID = localStorage.getItem("active_campaign_ID");
        axios.defaults.headers.common["Authorization"] = token;
        if (this.searchQuery !== "") {
          axios
            .get("/conversations", {
              params: {
                Campaign_ID: active_campaign_ID,
                search_query: this.searchQuery,
                limit: this.limit,
                page: 1,
              },
            })
            .then((resp) => {
              this.messages = resp.data.data;
            });
        } else {
          this.messages = this.filterMessages;
        }
      }, 500);
    },
    getContacts() {
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/getcontacts", {
          params: {
            start: 1,
            search_query: this.searchQuery,
            limit: this.limit,
            page: 1,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.contacts = resp.data.data;
        });
    },
    checkOthers(event) {
      this.form.recipient.id = "";
      var value = event.target.value;
      if (this.others.length > 0 && value) {
        this.searchothers(value);
      } else {
        this.filterOthers = [];
      }
    },
    setOthersValue(result) {
      this.filterOthers = [];
      this.form.phone_number = result.phone;
      this.form.recipient.name = result.name;
      this.form.recipient.id = result.id;
      this.$refs.phone_number.focus();
    },
    searchothers(value) {
      this.filterOthers = this.others.filter((other) => {
        return value
          .toLowerCase()
          .split(" ")
          .every((v) => other.name.toLowerCase().includes(v));
      });
    },
    onChangeOthers() {
      if (!this.form.recipient.id) {
        this.form.phone_number = "";
      }
    },
    changeType() {
      this.form.phone_number = this.form.template_id = this.form.template = this.form.physician_id = this.form.asset = this.form.recipient.name = this.form.recipient.id = this.oldPhoneNumber = this.$refs.prescriber.value =
        "";

      // Reset fields
      this.perscriberInactive = true;
      this.showAssets = false;
      this.templates = this.assets = [];
    },
    phoneNumberValidation() {
      let phone = this.form.phone_number;
      phone = phone.replace(/\W/g, "");
      this.form.phone_number = phone;
      if (!Number(phone)) {
        this.phoneNumberError = true;
        return false;
      }
      if (phone.length < 10) {
        this.phoneNumberError = true;
        return false;
      } else if (phone.length == 10) {
        this.phoneNumberError = false;
        return true;
      } else if (phone.length == 11) {
        var res = phone.charAt(0);
        if (res != 1) {
          this.phoneNumberError = true;
          return false;
        } else {
          this.phoneNumberError = false;
          return true;
        }
      } else {
        this.phoneNumberError = true;
        return false;
      }
    },
    checkTemplates() {
      if (
        this.form.phone_number != this.oldPhoneNumber ||
        this.form.phone_number == ""
      ) {
        this.form.template = this.oldPhoneNumber = this.form.template_id = "";
        this.templates = this.assets = [];
      }

      this.phoneNumberValidation();
    },
    getResultValue(result) {
      return result.HCP_name;
    },
    async search(input) {
      if (input.length < 2) {
        return [];
      }
      let token = this.$store.state.access_token;
      axios.defaults.headers.common["Authorization"] = token;
      let prescribers = await axios.get("/prescribers", {
        params: {
          name: input,
        },
      });
      return prescribers.data.data.filter((prescriber) => {
        return (
          prescriber.first_name.toLowerCase().startsWith(input.toLowerCase()) ||
          prescriber.last_name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    async handleSubmit(result) {
      this.form.physician_id = result;
      this.form.prescriber_id = result.prescriber_id;
      this.perscriberInactive = false;

      let token = this.$store.getters.getAccessToken;
      axios.defaults.headers.common["Authorization"] = token;
      if (this.form.type === 0) {
        this.form.phone_number = result.Phone_Number;
        this.$refs.phone_number.focus();
      } else {
        let othersRes = await axios.get("/other-contact-lists", {
          params: {
            prescriber_id: result.prescriber_id,
          },
        });
        this.others = othersRes.data.data;
      }
    },
    async getConversationScreen() {
      console.log(this.form);
      let details = {
        phone_number: this.form.phone_number,
        HCP_name: this.form.physician_id.HCP_name,
        prescriber_id: this.form.physician_id.prescriber_id,
        type: this.form.type,
        recipient_id: this.form.recipient.id,
        recipient_name: this.form.recipient.name,
      };
      console.log(this.form.recipient.name);
      if (this.form.type == 1 && this.form.recipient.id == "") {
        let resp = await axios.post("/other-contacts", {
          prescriber_id: this.form.physician_id.prescriber_id,
          recipient_name: this.form.recipient.name,
          phone_number: this.form.phone_number,
        });
        console.log(resp.data.data);
        details.recipient_id = resp.data.data[0].id;
        console.log(resp.data.data[0].id);
      }
      this.$store.commit("conversationOpeningDetails", details);
      this.$router.push("/conversation");
    },
  },
 
  components: {
    CallBox
  },
}
</script>