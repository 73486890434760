<template>
  <div class="chat-box">
    <!-- If Image -->
    <div class="chat-box-profile avatar" @click="Gotoprofile(dataObj)">
      <img src="@/assets/img/user.png" alt="" />
    </div>
    <div class="chat-box-details">
      <h5 v-if="dataObj.role_id == 1">{{ dataObj.user_name }}</h5>
      <h5 v-else>{{ dataObj.phone_extension_name }}</h5>
      <h6>{{ wformatPhoneNumber(getTenDigitPhoneNumber(dataObj.phone)) }}</h6>
    </div>
    <div class="chat-box-date-status">
      <button class="sidebar-main-icon btn-icon btn-light-info" @click="$emit('getConversationScreen',dataObj)">
          <feather type="message-square" size="18"></feather>
      </button>
      <button class="sidebar-main-icon btn-icon btn-light-success" @click="$emit('openCallModel',dataObj)">
          <feather type="phone" size="18"></feather>
      </button>
    </div>
   
  </div>
</template>

<script>

export default {
  props: {  
   dataObj:{
     type: Object
   }
  },
  methods: {
    Gotoprofile(contactInfo){
      // set in store.
      this.$store.commit(
              "setContactDetails",
              contactInfo
            );
      this.$router.push("/profile/" + contactInfo.user_name)
    }
  },
};
</script>