<template>
    <div>
        <!-- Nav Begin -->
        <nav :class="{active: toggleNav}" class="nav-small">
            <div class="nav-small-logo">
                <router-link to="/">
                    <img src="@/assets/img/logo-icon.png" alt="">
                </router-link>
            </div>
            <div class="nav-main">
                <ul class="nav-top">
                    <li v-for="(menu, index) in menus.top" :key="index">
                        <!-- Add class "active" if active -->
                        <router-link :to="menu.path" v-if="menu.path" class="btn-light btn-icon" data-toggle="tooltip" :data-title="menu.title">
                            <feather :type="menu.icon" size="15"></feather>
                        </router-link>
                        <a v-else href="#" class="btn-light btn-icon" @click.prevent="dynamicTab(menu.target)" data-toggle="tooltip" :data-title="menu.title">
                            <feather :type="menu.icon" size="15"></feather>
                        </a>
                    </li>
                    
                </ul>
                <ul class="nav-bottom">
                    <li>
                        <!-- Add class "active" if active -->
                        <b-button v-b-modal.modal-1 class="btn-light btn-icon"><feather type="log-out" size="15"></feather></b-button>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Nav End -->
        <SidebarDynamic :id="sidebarTab" :active="navtoggle" @navtoggle="navtoggle = false" />

        <!-- Logout Modal -->
        <b-modal id="modal-1" hide-footer hide-header ref="logoutConfirmModal" centered>
            <p class="my-4 text-center font-weight-bold">Are you sure want to Logout ?</p>
            <div class="row">
                <div class="col-6 text-right">
                    <button class="btn btn-md btn-success" type="button" @click="logout"><feather type="check" size="15"></feather> Yes</button>                            
                </div>
                <div class="col-6 text-left">
                    <button class="btn btn-md btn-danger" type="button" @click="hideModal"><feather type="x" size="15"></feather> No</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import SidebarDynamic from "@/components/Sidebar/SidebarDynamic.vue";
export default {
    data(){
        return{
            menus:{
                top:[
                    {
                        title: 'Profile',
                        icon: 'user',
                        path: '/profile'
                    },
                    {
                        title: 'Assets',
                        icon: 'folder',
                        path: '',
                        target: 1
                    },
                     {
                        title: 'Pre-Approved Templates',
                        icon: 'message-square',
                        path: '',
                        target: 2
                    }
                ],
                bottom:[
                    {
                        title: 'Sign out',
                        icon: 'log-out',
                        path: '/'
                    }
                ]
            },
            navtoggle: false,
            sidebarTab: 1
        }
    },
    methods:{
        hideModal() {
            this.$refs['logoutConfirmModal'].hide()
        },
        async logout() {
            try {
                await this.$auth.logoutRedirect('/logout');
            } catch (err) {
                console.log(err);
            }        
        },
        dynamicTab(target){
            this.sidebarTab = target;
            // this.navtoggle = !this.navtoggle;
            this.navtoggle = true
        }
    },
    components:{
        SidebarDynamic
    },
    props:{
        toggleNav: Boolean
    }
}
</script>
