import Vue from "vue";
import axios from "axios";
import qs from "qs";

// Generate a secure random string using the browser crypto functions
function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
    ""
  );
}

// Calculate the SHA256 hash of the input text.
function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v) {
  const hashed = await sha256(v);
  return base64urlencode(hashed);
}

// Encode Params
function encodeQueryData(data) {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
}
// Parse a query string into an object
function parseQueryString(string) {
  if (string == "") {
    return {};
  }
  var segments = string.split("&").map((s) => s.split("="));
  var queryString = {};
  segments.forEach((s) => (queryString[s[0]] = s[1]));
  return queryString;
}
// PKCE HELPER FUNCTIONS End

const oidcMethods = {
  async signin(config) {
    // Clear storage
    localStorage.clear();
    // Create and store a random "state" value
    var state = generateRandomString();
    localStorage.setItem("pkce_state", state);
    localStorage.setItem("sso_type", config.type);
    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var code_verifier = generateRandomString();
    localStorage.setItem("pkce_code_verifier", code_verifier);

    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var nonce = generateRandomString();
    localStorage.setItem("pkce_nonce", nonce);

    // Hash and base64-urlencode the secret to use as the challenge
    var code_challenge = await pkceChallengeFromVerifier(code_verifier);

    const oAuthurl = config.metadata.authorization_endpoint;
    const params = {
      client_id: config.clientId,
      code_challenge: code_challenge,
      code_challenge_method: "S256",
      nonce: nonce,
      redirect_uri: config.redirectUri,
      response_type: config.responseType,
      // client_secret: config.clientSecret,
      state: state,
      scope: config.scope,
    };

    // Build the authorization URL
    const url = oAuthurl + "?" + encodeQueryData(params);

    // Redirect to the authorization server
    window.location = url;
  },
  logoutRedirect(url) {
    localStorage.clear();
    window.location = url;
  },
  accessToken(config) {
    return new Promise(function(resolve, reject) {
      var query = parseQueryString(window.location.search.substring(1));
      const queryConfig = {
        url: config.metadata.token_endpoint,
        data: {
          grant_type: "authorization_code",
          client_id: config.clientId,
          client_secret: config.clientSecret,
          redirect_uri: config.redirectUri,
          code: query.code,
          code_verifier: localStorage.getItem("pkce_code_verifier"),
        },
      };

      // Check if the server returned an error string
      if (query.error) {
        console.log("Error returned from authorization server: " + query.error);
      }

      // If the server returned an authorization code, attempt to exchange it for an access token
      if (query.code) {
        // Verify state matches what we set at the beginning
        if (localStorage.getItem("pkce_state") != query.state) {
          alert("Invalid state");
        } else {
          // Exchange the authorization code for an access token
          axios({
            method: "post",
            url: queryConfig.url,
            data: qs.stringify(queryConfig.data),
            headers: {
              "content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
            .then(function(response) {
              // localStorage.setItem("access_token", response.data.access_token);
              localStorage.setItem(
                "okta-token-storage",
                JSON.stringify(response.data)
              );

              // Replace the history entry to remove the auth code from the browser address bar
              const currentPath = window.location.pathname;
              window.history.replaceState({}, null, currentPath);
              resolve();
            })
            .catch(function(err) {
              reject(err);
            });
        }

        // Clean these up since we don't need them anymore
        // localStorage.removeItem("pkce_state");
        localStorage.removeItem("pkce_code_verifier");
      }
    });
  },
};

export default Vue.prototype.$auth = oidcMethods;
