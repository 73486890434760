<template>
  <BlockUI v-if="loading" message="Please wait"><img src="@/assets/img/preloader.gif" width="90"/></BlockUI>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.getAccessTokenWithUserDetails();
  },
  created() {
    this.$store.commit("initialize");
  },
  methods: {
    async getAccessTokenWithUserDetails() {
      let vm = this;
      var token = this.$store.getters.getAccessToken;
      if (!token) {
        this.$store
          .dispatch("login", this.form)
          .then((resp) => {
            vm.setStorageItem(resp);
            vm.checkCampaign(resp.campaigns);
          })
          .catch((err) => {
            console.log(err.response);
            if(err.response.status==403){
              this.$router.push("/error/403"); 
            }
          });
      } else {
        this.checkCampaign(this.$store.getters.getAllCampaigns);
      }
    },
    checkCampaign(campaigns) {
      console.log(campaigns);
      this.$store.commit("setActiveCampignInfo", {
        active_campaign_ID: campaigns[0].CampaignID,
        active_campaign: campaigns[0].CampaignName,
      });
      if (campaigns.length > 1) {
        localStorage.setItem("active_campaign_ID", campaigns[0].CampaignID);
        localStorage.setItem("active_campaign", campaigns[0].CampaignName);
         this.loading = false;
        this.$router.push("/history/chats");
      } else {
        localStorage.setItem("active_campaign_ID", campaigns[0].CampaignID);
        localStorage.setItem("active_campaign", campaigns[0].CampaignName);
        this.loading = false;
        this.$router.push("/history/chats");
      }

      // if (campaigns.length > 1) {
      //   this.$router.push("/campaign");
      // } else {
      //   localStorage.setItem("active_campaign_ID", campaigns[0].CampaignID);
      //   localStorage.setItem("active_campaign", campaigns[0].CampaignName);
      //   this.loading = false;
      //   this.$router.push("/");
      // }
    },
    setStorageItem(data) {
      localStorage.setItem("access_token", data.token);
      localStorage.setItem("name", data.name);
      localStorage.setItem("id", data.id);
      localStorage.setItem("virtual_number", data.virtual_number);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("campaigns", JSON.stringify(data.campaigns));
    },
  },
};
</script>