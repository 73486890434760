<template>
  <div>


    <div class="search-box sidebar-main-search px-2 pb-2">
      <form class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="'Search Chat'"
          ref="search_input"
          v-model="searchQuery"
          @keyup="getData"
        />
        <div class="input-group-append">
          <button class="btn" type="button" @click="getData">
            <feather type="search" size="18"></feather>
          </button>
        </div>
      </form>
    </div>
    <ul class="chat-main scrollbox scrollbox-lg">
      <li v-for="message in messages" :key="message.id">
        <ChatBox type="img" :message="message" />
      </li>
      <infinite-loading  :identifier="infiniteId"
                         @infinite="infiniteHandler">
        <!-- <div slot="spinner">Loading...</div> -->
        <div slot="no-more"></div>
        <div slot="no-results"></div>
        <!-- If No messages -->
        <div slot="no-results" class="messenger-list text-center">
          <h4 class="text-black mb-1">No messages yet!</h4>
          <p class="mb-3">
            No messages in your inbox, yet! Start sending the message to the
            Rep.
          </p>
          <router-link to="/home" class="btn btn-primary">Go to App</router-link>
        </div>
      </infinite-loading>
    </ul>
    <!-- <b-link
      :to="{ name: 'home' }"
      class="btn-icon btn-primary floating-btn floating-sidebar"
    >
      <feather type="plus" size="15"></feather>
    </b-link> -->

    <!-- Modal Chat -->
    <!-- <b-modal id="modal-3" hide-footer centered title="Select Contact">
      <form action="" @submit.prevent="getConversationScreen">
        <div class="form-group">
          <p class="text-gray-400 font-weight-bold">Select Type</p>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="physician"
              name="type"
              v-model.number="form.type"
              @change="changeType()"
              value="0"
              class="custom-control-input"
            />
            <label for="physician" class="custom-control-label"
              >Prescriber</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="others"
              name="type"
              v-model.number="form.type"
              @change="changeType()"
              value="1"
              class="custom-control-input"
            />
            <label for="others" class="custom-control-label">Others</label>
          </div>
        </div>
        <div class="form-group">
          <p class="text-gray-400 font-weight-bold">Prescriber</p>
          <autocomplete
            ref="prescriber"
            @submit="handleSubmit"
            :search="search"
            :debounceTime="500"
            :get-result-value="getResultValue"
            placeholder="Search for a Prescriber"
            aria-label="Search for a Prescribers"
          ></autocomplete>
        </div>
        <div
          class="form-group floating-form form-autocomplete"
          :class="{ 'form-autocomplete-shown': filterOthers.length > 0 }"
          v-if="form.type == 1"
        >
          <input
            type="text"
            placeholder="Please enter Recipient name"
            class="form-control"
            :disabled="perscriberInactive"
            v-model="form.recipient.name"
            @change="onChangeOthers()"
            @keyup="checkOthers($event)"
            autocomplete="off"
          />
          <label for="">Recipient</label>
          <ul class="form-autocomplete-list" v-if="filterOthers.length > 0">
            <li
              v-for="(other, index) in filterOthers"
              :key="index"
              @click="setOthersValue(other)"
            >
              {{ other.name }}
            </li>
          </ul>
        </div>
        <div class="form-group floating-form">
          <input
            type="text"
            placeholder="Please enter the phone number"
            class="form-control"
            v-model="form.phone_number"
            ref="phone_number"
            :readonly="perscriberInactive"
            @change="checkTemplates"
            @focus="checkTemplates"
            autocomplete="off"
          />
          <label for="">Phone Number</label>
          <p
            class="label-error"
            v-if="phoneNumberError"
            v-show="form.phone_number != ''"
          >
            Enter a valid phone number
          </p>
        </div>

        <div class="row">
          <div class="col-8 offset-2">
            <button :disabled="processing" class="btn btn-primary btn-block btn-rounded">
              <feather type="chevron-right" size="15"></feather>
            </button>
          </div>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Close
        </b-button>
      </template>
    </b-modal> -->
  </div>
</template>

<script>
import ChatBox from "@/components/Chat/ChatBox.vue";
import axios from "axios";
import Pusher from "pusher-js";
export default {
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      chatItems: [
      
      ],
      chatTab: 0,
      page: 1,
      limit: 10,

      msg: "Loading Conversation",
      url: `<img src="/preloader.gif" width="90"/>`,
      loading: true,
      isDrawer: false,
      messages: [],
      filterMessages: [],
      conversations: false,
      showSearch: false,
      searchQuery: "",
      timeoutHandle: "",
      contacts: [],

      processing: false,
      form: {
        phone_number: "",
        physician_id: "",
        prescriber_id: "",
        asset: "",
        template_id: "",
        template: "",
        recipient: {
          id: "",
          name: "",
        },
        type: 0,
      },
      perscriberInactive: true,
      phoneNumberError: true,
      filterOthers: [],
      infiniteId: +new Date(),
    };
  },
  components: {
    ChatBox,

  },
  created() {
    this.$store.commit("initialize");
  },
  mounted() {
    this.subscribe();
  },
  methods: {
    subscribe() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, { cluster: process.env.VUE_APP_PUSHER_CLUSTER });
      pusher.subscribe("chat");
      console.log(this.$store.getters.getLoggedInID.toLowerCase())
      pusher.bind(this.$store.getters.getLoggedInID.toLowerCase(), (data) => {
        console.log(data);
        this.updateData();
      });
    },
    infiniteHandler($state) {
      if(this.ajaxreq){
        this.ajaxreq.cancel();
      }
      this.ajaxreq = axios.CancelToken.source();
      var token = localStorage.getItem("access_token"),
        active_campaign_ID = localStorage.getItem("active_campaign_ID");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/conversations", {
          cancelToken: this.ajaxreq.token ,
          params: {
            Campaign_ID: active_campaign_ID,
            search_query: this.searchQuery,
            limit: this.limit,
            page: this.page,
          },
        })
        .then(({ data }) => {
          if (data.data.length) {
            if (data.data.length === this.limit) {
              this.page += 1;
            } else {
              $state.complete();
            }
            this.messages.push(...data.data);
            this.filterMessages = this.messages;
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    updateData() {
        var token = localStorage.getItem("access_token"),
          active_campaign_ID = localStorage.getItem("active_campaign_ID");
        axios.defaults.headers.common["Authorization"] = token;
        axios
          .get("/conversations", {
            params: {
              Campaign_ID: active_campaign_ID,
              search_query: this.searchQuery,
              limit: this.limit,
              page: 1
            },
          })
          .then((resp) => {
            this.messages = resp.data.data;
          });
    },
    getData() {
      window.clearTimeout(this.timeoutHandle);

      this.timeoutHandle = setTimeout(() => {
        this.page = 1;
        this.messages=[];
        this.infiniteId += 1;

      }, 500);
    },
    checkOthers(event) {
      this.form.recipient.id = "";
      var value = event.target.value;
      if (this.others.length > 0 && value) {
        this.searchothers(value);
      } else {
        this.filterOthers = [];
      }
    },
    setOthersValue(result) {
      this.filterOthers = [];
      this.form.phone_number = result.phone;
      this.form.recipient.name = result.name;
      this.form.recipient.id = result.id;
      this.$refs.phone_number.focus();
    },
    searchothers(value) {
      this.filterOthers = this.others.filter((other) => {
        return value
          .toLowerCase()
          .split(" ")
          .every((v) => other.name.toLowerCase().includes(v));
      });
    },
    onChangeOthers() {
      if (!this.form.recipient.id) {
        this.form.phone_number = "";
      }
    },
    changeType() {
      this.form.phone_number = this.form.template_id = this.form.template = this.form.physician_id = this.form.asset = this.form.recipient.name = this.form.recipient.id = this.oldPhoneNumber = this.$refs.prescriber.value =
        "";

      // Reset fields
      this.perscriberInactive = true;
      this.showAssets = false;
      this.templates = this.assets = [];
    },
    phoneNumberValidation() {
      let phone = this.form.phone_number;
      phone = phone.replace(/\W/g, "");
      this.form.phone_number = phone;
      if (!Number(phone)) {
        this.phoneNumberError = true;
        return false;
      }
      if (phone.length < 10) {
        this.phoneNumberError = true;
        return false;
      } else if (phone.length == 10) {
        this.phoneNumberError = false;
        return true;
      } else if (phone.length == 11) {
        var res = phone.charAt(0);
        if (res != 1) {
          this.phoneNumberError = true;
          return false;
        } else {
          this.phoneNumberError = false;
          return true;
        }
      } else {
        this.phoneNumberError = true;
        return false;
      }
    },
    checkTemplates() {
      if (
        this.form.phone_number != this.oldPhoneNumber ||
        this.form.phone_number == ""
      ) {
        this.form.template = this.oldPhoneNumber = this.form.template_id = "";
        this.templates = this.assets = [];
      }

      this.phoneNumberValidation();
    },
    getResultValue(result) {
      return result.HCP_name;
    },
    async search(input) {
      if (input.length < 2) {
        return [];
      }
      let token = this.$store.state.access_token;
      axios.defaults.headers.common["Authorization"] = token;
      let prescribers = await axios.get("/prescribers", {
        params: {
          name: input,
        },
      });
      return prescribers.data.data.filter((prescriber) => {
        return (
          prescriber.first_name.toLowerCase().startsWith(input.toLowerCase()) ||
          prescriber.last_name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    async handleSubmit(result) {
      this.form.physician_id = result;
      this.form.prescriber_id = result.prescriber_id;
      this.perscriberInactive = false;

      let token = this.$store.getters.getAccessToken;
      axios.defaults.headers.common["Authorization"] = token;
      if (this.form.type === 0) {
        this.form.phone_number = result.Phone_Number;
        this.$refs.phone_number.focus();
      } else {
        let othersRes = await axios.get("/other-contact-lists", {
          params: {
            prescriber_id: result.prescriber_id,
          },
        });
        this.others = othersRes.data.data;
      }
    },
    async getConversationScreen() {
      console.log(this.form);
      let details = {
        phone_number: this.form.phone_number,
        HCP_name: this.form.physician_id.HCP_name,
        prescriber_id: this.form.physician_id.prescriber_id,
        type: this.form.type,
        recipient_id: this.form.recipient.id,
        recipient_name: this.form.recipient.name,
      };
      console.log(this.form.recipient.name);
      if (this.form.type == 1 && this.form.recipient.id == "") {
        let resp = await axios.post("/other-contacts", {
          prescriber_id: this.form.physician_id.prescriber_id,
          recipient_name: this.form.recipient.name,
          phone_number: this.form.phone_number,
        });
        console.log(resp.data.data);
        details.recipient_id = resp.data.data[0].id;
        console.log(resp.data.data[0].id);
      }
      this.$store.commit("conversationOpeningDetails", details);
      this.$router.push("/conversation");
    },
  },
};
</script>