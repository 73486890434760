import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeLayout from '../views/App/Home'

import Home from '@/components/Home/Home.vue'
import ContactTabView from "@/components/Contact/ContactTabView.vue";
import ChatTabView from "@/views/Layouts/ChatLogLayout";
//import ContactLayout from '../views/App/Contact'
//import ChatLogLayout from '../views/App/ChatLogs.vue'
import CallLogLayout from '@/views/App/CallLogs'


import Error from '../components/Error.vue'

import Profile from '../views/Profile.vue'
import Log from '../views/CallLog.vue'
import userProfile from '../views/ProfileCode.vue'
import Chat from '../views/Chat.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import ConversationCreate from '../views/ConversationCreate'
import LoginValidation from "@/views/LoginValidation";
import HandleCallback from '../views/HandleCallback.vue'

// Home Layout
// import ApplicationLayout from '../views/Layouts/Application.vue'

// // Okta
// import Auth from '@okta/okta-vue'
// Vue.use(Auth, {
//   issuer: 'https://dev-357520.okta.com/oauth2/default',
//   // clientId: '0oa11706weTmgui9G4x7',
//   clientId: '0oa11706weTmgui9G4x7',
//   postLogoutRedirectUri: window.location.origin + '/logout',
//   redirectUri: window.location.origin +'/implicit/callback',
//   scopes: ['openid', 'profile', 'email']
// });

Vue.use(VueRouter)

const routes = [
  {
    path: "/implicit/callback",
    component: HandleCallback
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/error/:id',
    name: 'Error',
    component: Error,
  },
  
  {
    path: '/',
    name: 'HomeLayout',
    component: HomeLayout,
    meta: { requireAuth: true },
    children:[
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: { requireAuth: true }
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: ContactTabView,
        meta: { requireAuth: true }
      },
      // {
      //   path: '/chats',
      //   name: 'chats',
      //   component: ChatTabView,
      //   meta: { requireAuth: true }
      // }
      {
        path: '/history/chats',
        name: 'chatHistory',
        component: ChatTabView,
        meta: { requireAuth: true }
      },
      {
        path: '/history/call',
        name: 'callHistory',
        component: CallLogLayout,
        meta: { requireAuth: true }
      },
    ]
  },
  
  {
    path: "/profile",
    name: 'userProfile',
    component: userProfile,
    meta: { requireAuth: true }
  },
  {
    path: "/profile/:prescriber_id",
    name: 'Profile',
    component: Profile,
    meta: { requireAuth: true }
  },
  {
    path: "/log/:prescriber_id",
    name: 'Log',
    component: Log,
    meta: { requireAuth: true }
  },
  {
    path: "/login/validation",
    name: "LoginValidation",
    component: LoginValidation
  },
  {
    path: "/chat/all",
    name: 'Chat',
    component: Chat,
    meta: { requireAuth: true }
  },
  {
    path: "/conversation",
    name: 'ConversationCreate',
    component: ConversationCreate,
    meta: { requireAuth: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
})


// async function guardMyroute(to, from, next){
//   var isAuthenticated= await Vue.prototype.$auth.isAuthenticated();
//   if(isAuthenticated) {
//       next('/');
//   } else{
//       next();
//   }
// }

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (!localStorage.getItem("okta-token-storage")) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
