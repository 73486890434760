<template>
    <main class="login-screen">
        <a href="#" class="login-screen-logo">
            <img src="@/assets/img/logo.png" alt="">
        </a>
        <div class="login-screen-body">

            <h2 class="bold mb-1 text-gray-400">Let's sign you in.</h2>
            <p>Your organization uses following login modes to access the application.</p>
            <ul class="list-inline list-inline-responsive-sm">
              <li>
                <button class="btn btn-okta" type="button" @click.prevent="login(okta)">Login with Okta</button>
              </li>
              <li>
                <button class="btn btn-ping" type="button" @click.prevent="login(ping)">Login with Ping</button>
              </li>
            </ul>
        </div>
    </main>
</template>

<script>
import oktaConfig from '../config/okta'
import pingConfig from '../config/ping'
export default {
  data(){
    return{
      okta: oktaConfig,
      ping: pingConfig
    }
  },
  mounted(){
    this.checkLoginStatus();
  },
  methods: {
    checkLoginStatus(){
       let token = localStorage.getItem('access_token');
       if(token)
         this.$router.push('/history/chats');
         
    },
    login (config) {
      this.$auth.signin(config);
    }
  }
}
</script>