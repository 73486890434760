<template>
  <!-- Modal Call -->
  <b-modal
    id="modal-6"
    ref="callmodal"
    hide-footer
    centered
    
    modal-class="call-dialog"
  >
    <div
      class="call-dialog-body"
      style="background-image: url(@/assets/img/user-lg.jpg)"
    >
      <div class="call-dialog-details">
        <h3 class="bolder text-black">{{ data.contact_name }}</h3>
        <h5 class="text-gray-400 mb-1">{{ data.phone_number }}</h5>
     
        <div class="call-dialog-btns" >
          <button
            class="btn-icon btn-icon-lg"
            @click="toggleCall"
            :class="[onPhone ? 'btn-danger' : 'btn-success']"
          >
            <feather :type="[onPhone ? 'x' : 'phone']" size="15"></feather>
          </button>
          <button class="btn-icon btn-light" v-if="onPhone" @click="toggleMute">
            <feather :type="[muted ? 'mic-off' : 'mic']" size="15"></feather>
          </button>
           <button class="btn-icon btn-icon-lg btn-danger" v-else @click="closeModal">
             <feather type="x" size="15"></feather>
          </button>
        </div>
     
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()"> Close </b-button>
    </template>
  </b-modal>
</template>
<script>
const Twilio = require("twilio-client");
import axios from "axios";
export default {
  data() {
    return {
      muted: false,
      onPhone: false,
      log: "Connecting...",
      connection: null,
    };
  },
  props: ['data'],
  created() {
    var self = this;

    var token = localStorage.getItem("access_token");
    axios.defaults.headers.common["Authorization"] = token;

    // Fetch Twilio capability token from our Node.js server
    axios
      .get("/voice-token")
      .then(function (data) {
        Twilio.Device.setup(data.data.token);
      })
      .catch(function (err) {
        console.log(err);
        self.log = "Could not fetch token, see console.log";
      });

    // Configure event handlers for Twilio Device
    Twilio.Device.disconnect(function () {
      self.onPhone = false;
      self.connection = null;
      self.log = "Call ended.";
    });

    Twilio.Device.ready(function () {
      self.log = "Connected";
    });
  },
  methods: {
    closeModal(){
        this.$bvModal.hide('modal-6')
    },
    toggleMute: function() {
        this.muted = !this.muted;
        Twilio.Device.activeConnection().mute(this.muted);
    },
    toggleCall: function () {
      if (!this.onPhone) {
        this.muted = false;
        this.onPhone = true;
        // make outbound call with current number
        let n = this.data.phone_number;
        console.log(n);
        this.connection = Twilio.Device.connect({
          number: n,
          fromNumber:this.$store.getters.getLoggedInVirtualNumber,
        });
        this.log = "Calling " + n;
      } else {
        // hang up call in progress
        Twilio.Device.disconnectAll();
        this.$bvModal.hide('modal-6')
      }
    },
  },
};
</script>