<template>
    <main class="login-screen">
        <a href="#" class="login-screen-logo">
            <img src="@/assets/img/logo.png" alt="">
        </a>
        <div class="login-screen-body">

            <h2 class="bold mb-1 text-gray-400">Goodbye</h2>
            <p>You are now logged out of the application.</p>
            <router-link class="btn btn-info" :to="redirectTo.path">Go back to {{ redirectTo.title }}</router-link>
        </div>
    </main>
</template>
<script>
export default {
    data(){
        return{
            redirectTo: {
                title: '',
                path: ''
            }
        }
    },
    methods:{
        checkAccessToken(){
            if(localStorage.getItem('access_token')) {
                this.redirectTo = {
                    title: 'Application',
                    path: '/app'
                }
            } else{
                this.redirectTo = {
                    title: 'Login',
                    path: '/'
                }
            }
        }
    },
    mounted(){
        this.checkAccessToken();
    }
}
</script>
