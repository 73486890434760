<template>
  <div class="home wrapper">
    <aside class="sidebar">
      <CallLogLayout @clicked="toggleMenuFunc" />
      <div class="sidebar-footer">
        Powered by <img src="/@/assets/img/p360_logo.png" alt="" />
      </div>
    </aside>
  </div>
</template>

<script>
// @ is an alias to /src
import CallLogLayout from "@/views/Layouts/CallLogLayout";

export default {
  name: "Home",
  components: {
    CallLogLayout,
  },
  data() {
    return {
      toggleMenu: false,
      showNav: false,
    };
  },
  methods: {
    passToNav(value) {
      this.showNav = value;
    },
    toggleMenuFunc() {
      this.toggleMenu = !this.toggleMenu;
      this.showNav = this.toggleMenu;
    },
    autoAdjustHeight(){
      const header = document.getElementsByClassName('sidebar-main')[0].clientHeight;
      const sidebarDefault = document.getElementsByClassName('sidebar-default')[0];
      const subMenu = sidebarDefault.getElementsByClassName('px-2 mb-2')[0].clientHeight;
      // const search = document.getElementsByClassName('search-box')[0].clientHeight;
      const footer = document.getElementsByClassName('sidebar-footer')[0].clientHeight;
      const screenHeight = window.innerHeight;
      document.getElementsByClassName('chat-main')[0].style.height = (screenHeight - (header + subMenu + footer + 20)) + 'px';
    }
  },
  mounted() {
    this.autoAdjustHeight();
  },
  updated() {
    this.autoAdjustHeight();
  },
};
</script>
