<template>
  <!-- Content Begin -->
  <main class="theme-main">
    <div class="theme-main-body">
      <div class="theme-main-header" :class="{'theme-main-header-lg': optedOut}">
        <div class="bg-primary text-white text-center theme-main-header-full">
          <p>Best time to connect : 10:00 AM - 03:30 PM</p>
        </div>
        <div v-if="optedOut" class="bg-danger text-white text-center theme-main-header-full">
          <p>Recipient opted out.</p>
        </div>
        <div class="theme-main-header-left">
          <button
            class="btn-inverse-light btn-icon mr-1"
            @click="goBack"
            >
             <feather type="chevron-left" size="18"></feather>
             </button>
         
          <div class="avatar avatar-lg theme-main-header-avatar mr-2">
            <img src="@/assets/img/user.png" alt="" />
          </div>
          <div class="theme-main-header-details">
            <h5>{{ recepient_name }}</h5>
            <p>{{ recepient_no }}</p>
          </div>
        </div>
        <!-- <div class="theme-main-header-col-right">
          <div class="theme-main-header-right">
            <ul class="list-inline">
              <li>
                <a
                  href="#"
                  class="btn-inverse-light btn-icon"
                >
                  <feather type="phone" size="18"></feather>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="btn-inverse-light btn-icon"
                >
                  <feather type="more-vertical" size="18"></feather>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
        <div
          class="search-box theme-main-header-search"
          :class="{ active: headerSearch }"
        >
          <form class="input-group">
            <div class="input-group-prepend">
              <button class="btn" type="button">
                <feather type="search" size="18"></feather>
              </button>
            </div>
            <input type="text" class="form-control" placeholder="Search...." />
            <div class="input-group-append">
              <button
                @click.prevent="headerSearch = false"
                class="btn"
                type="button"
              >
                <feather type="x" size="15"></feather>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div  class="theme-main-content" ref="themeContent" :class="[optedOut ? 'optedOut' : '', optedOut ? 'theme-main-content-lg': '']" id="chatBody">        
        <ul class="chat-list" id="chat-list" >
          <infinite-loading direction="top" 
          :identifier="infiniteId"
          @infinite="infiniteHandler">
            <!-- <div slot="spinner"></div> -->
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
          <li class="text-center" style="width: 100%; font-size: 12px;" v-if="msgDeleted">We’ve deleted older messages due to your org’s retention policy.</li>
          <li
            v-for="(chat, index) in chats"
            :key="index"
            :class="chat.event_log_ID ? 'replies' : 'sent'"
          >
            <Message
              v-if="!chat.is_archived"
              avatar="/img/user/user.png"
              :name="chat.sender_name"
              :thumbnail1="
                chat.attach1_type === '.jpg' ||
                chat.attach1_type === '.gif' ||
                chat.attach1_type === '.png'
                  ? chat.attach1
                  : ''
              "
              :thumbnail2="
                chat.attach2_type === '.jpg' ||
                chat.attach2_type === '.gif' ||
                chat.attach2_type === '.png'
                  ? chat.attach2
                  : ''
              "
              :vcard="chat.attach1_type === '.vcf' ? chat.attach1 : ''"
              :dateTime="chat.CreatedDate"
              :conversations="chat.Body"
            />
          </li>
        </ul>
      </div>
      <b-modal id="modal-2" ref="preApprovedTemplateModal" button-size="sm">
        <template #modal-title> Pre Approved Templates </template>
        <ul class="template-modal-list">
           
          <li
            v-for="(template, index) in templates"
            :id="'template' + index"
            :key="index"
            :class="{ selected: template.templateSelected }"
            @click="selectedTemplate('template' + index, index)"
          >
            <div class="template-modal-body">
              <div class="template-modal-body-title">
                {{ template.Template_Name }} {{ template.templateSelected }}
              </div>
              <p>
                <span>{{ template.Template_body }}</span>
              </p>
            </div>
            <!-- Add class active-assets/active-attachments accordingly for arrow effect -->
            <div
              class="template-modal-footer active-assets"
              v-if="template.show_assets && template.Asset"
            >
              <div class="template-modal-footer-top">
                <button class="template-modal-btn assets-btn text-gray-400">
                  Assets
                  <span class="badge badge-info">{{
                    JSON.parse(template.Asset).length
                  }}</span>
                </button>
              </div>
              <div class="template-modal-footer-bottom">
                <ul class="font-sm list list-styled list-styled-gray-400">
                  <li
                    v-for="(value, index) in JSON.parse(template.Asset)"
                    :key="index"
                  >
                    <a :href="value.file_url" target="__blank">{{ value.Asset_Name }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="floating-btn">
              <button
                @click="sendPreApprovedTemplates"
                :disabled="sendPreApprovedMessageProcessing"
                class="btn btn-primary btn-sm"
              >
                <feather type="send" size="18"></feather>
              </button>
            </div>
          </li>
        </ul>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->

          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>
          <!-- Button with custom close trigger value -->
        </template>
      </b-modal>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form class="theme-main-footer" v-if="!optedOut" @submit.prevent="sendCustomMessage">
        <div class="theme-main-footer-error">
          <p>Number is opted out</p>
        </div>
        <div class="theme-main-footer-btns">
          <!-- <a href="#" class="btn-light btn-icon">
            <feather type="smile" size="18"></feather>
          </a> -->
          <button
            type="button"
            class="btn-light btn-icon"
            v-b-modal.modal-2
            @click="showModal"
            :disabled="optedOut "
          >
            <feather type="folder" size="18"></feather>
          </button>
        </div>
        <div class="theme-main-footer-writer">
          <ValidationProvider v-slot="v" rules="secret">
          <label class="input-sizer stacked">
            <textarea
              placeholder="Write your message... "
              rows="1"
              ref="textareaChat"
              name="Input text"
              maxlength="1500"
              @keyup="updateElSize()"
              v-model="form.template_data"
              :disabled="optedOut"
            ></textarea>
          </label>
          <span class="error">{{ v.errors[0] }}</span>
          </ValidationProvider>
          
        </div>
        <div class="theme-main-footer-btns" >
          
          <button
            type="submit"
            class="btn-light btn-icon"
            :disabled="optedOut || (form.template_data.length == 0) ||invalid || sendCustomMessageProcessing "
          >
            <feather type="send" size="18"></feather>
          </button>
        </div>
      </form>
      </ValidationObserver>
    </div>

    <b-modal id="audio-modal" modal-header="false"> </b-modal>
  </main>
  <!-- Content End -->
</template>

<script>
import { ValidationProvider} from 'vee-validate';
import axios from "axios";
import Message from "@/components/Chat/Message";
import Pusher from "pusher-js";
export default {
  components: {
    Message,
    ValidationProvider,
  },
  data() {
    return {
      state: {},
      templates: [],
      msg: "Loading Conversation",
      url: `<img src="/preloader.gif" width="90"/>`,
      loading: true,
      recepient_name: "",
      recepient_no: "",
      page: 1,
      limit: 10,
      chats: [],
      name: "",
      form: {
        template_data: "",
      },
      isLoaded: false,
      templateModal: false,
      selectedTemplateData: false,
      headerSearch: false,
      selectedCampaignID:'',
      campaigns: '',
      optedOut: false,
      msgDeleted: false,
      filterconverstions: [],
      infiniteId: +new Date(),
      ajaxreq:null,
      sendCustomMessageProcessing:false,
      sendPreApprovedMessageProcessing:false,
    };
  },
  created() {
      this.$store.commit('initialize');
  },
  mounted() {
    this.subscribe();
    this.initializeUI();
    this.CheckPhoneNumberOptOutStatus();
    this.getProducts();
  },
  methods: {
    updateElSize() {
      this.$refs.textareaChat.style.height = "auto";
      this.$refs.textareaChat.style.height = this.$refs.textareaChat.scrollHeight + 'px';
      this.$refs.themeContent.style.paddingBottom = this.$refs.textareaChat.offsetHeight + 60 + "px";
    },
    goBack(){
       let details         = this.$store.getters.getConversationDetails;
       let conversation_id = details.SMSConversationID 
        console.log('ID' +conversation_id)
        this.readState(conversation_id)
        window.history.back()
    },
    CheckPhoneNumberOptOutStatus(){
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;

      axios.get('phone-number-optout-status',{
        params: {
          phone_number : this.recepient_no
        }
      })
      .then(resp => {
        console.log(resp)
        if(!resp.data.success){
          // 
          this.optedOut = true;
        }
        else{
          this.optedOut = false;
        }
      })
    },
    
     getProducts(){
      this.campaigns = this.$store.getters.getAllCampaigns; 
      this.selectedCampaignID = this.$store.getters.getActiveCampaignId; 
    },
     GetTheTemplateBasedOnTheProduct(){
          let conversationDetail = this.$store.getters.getConversationDetails;
          let active_campaign_ID = this.selectedCampaignID;
          let phone = "";
          let hcp_id = "";
          if (conversationDetail.event_log_ID) {
            phone = conversationDetail.toNumber.replace(/\W/g, "");
            hcp_id = conversationDetail.RecepientID;
          } else {
            phone = conversationDetail.fromNumber.replace(/\W/g, "");
            hcp_id = conversationDetail.SenderID;
          }

          // let phone = conversationDetail.phone_number;
          // let hcp_id =  conversationDetail.prescriber_id;
          let recipient_other_id  = conversationDetail.recipient_id;
          let data = {
            campaign_id: active_campaign_ID,
            phone: phone,
            hcp_id: hcp_id,
            type: conversationDetail.type ? 1 : 0 ,
            recipient_other_id: recipient_other_id,
          };
          axios
            .get("/templates", {
              params: data,
            })
            .then((resp) => {
              this.templates = resp.data.data;
            });

          this.templateModal = !this.templateModal;
      },
    initializeUI(){
       let details         = this.$store.getters.getConversationDetails;
       console.log("DETAILS")
       console.log(details);
       this.recepient_no   = details.To;
       this.recepient_name = details.recepient_name;
       let conversation_id = details.SMSConversationID 
       if(details.IsRead === false){
         this.readState(conversation_id)
       } 
    },
    readState(conversation_id){
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios.put('/read-at/' + conversation_id).then(resp => {
        console.log(resp)
      })
    },
    subscribe() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, { cluster: process.env.VUE_APP_PUSHER_CLUSTER });
      pusher.subscribe("chat");
      let binder = this.$store.getters.getConversationDetails.PhyscianId;
      console.log(binder);
       console.log("binder");
     pusher.bind(binder.toLowerCase(), (data) => {
        this.CheckPhoneNumberOptOutStatus();
        this.chats.push(data);
        this.scrollToBottom();
      });
    },
    sendPreApprovedTemplates() {
      let conversationDetail = this.$store.getters.getConversationDetails;
      let active_campaign_ID = this.$store.getters.getActiveCampaignId;
      let recipient_id       = (conversationDetail.RecepientRoleID == 3) ? conversationDetail.RecepientID : '';
      let phone              = conversationDetail.To.replace(/\W/g, "");
      let hcp_id             = conversationDetail.PhyscianId;
      let recipient_name     = (conversationDetail.RecepientRoleID == 3) ? conversationDetail.recepient_name.split('-')[1]: '';
      this.sendPreApprovedMessageProcessing=true;
     
      
      let data = {
        recipient_name : recipient_name,
        recipient_id   : recipient_id,
        physcian_id    : hcp_id,
         type          : (conversationDetail.RecepientRoleID == 3) ? 1: 0,
        template_id    : this.selectedTemplateData.Template_ID,
        assets         : this.selectedTemplateData.Asset,
        template       : this.selectedTemplateData.Template_body,
        recipient_phone: phone,
        Campaign_ID    : active_campaign_ID,
      };
      console.log("preapproved template data")
      console.log(data);
      axios
        .post("/send-sms", data)
        .then((resp) => {
          console.log(resp)
          if (resp.data.success) {
            this.sendPreApprovedMessageProcessing=false;
            this.closeTemplateSelected();
            alert(resp.data.message);
            this.$refs["preApprovedTemplateModal"].hide();
            this.getData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendCustomMessage() {
      let conversationDetail = this.$store.getters.getConversationDetails;
      let recipient_id =  (conversationDetail.RecepientRoleID == 3) ? conversationDetail.RecepientID: '';
      let recipient_name = (conversationDetail.RecepientRoleID == 3) ? conversationDetail.recepient_name.split('-')[1]: '';
      let physcian_id = conversationDetail.PhyscianId;
      this.sendCustomMessageProcessing=true;
      let data = {
        physcian_id      : physcian_id,
        recipient_name   : recipient_name,
        recipient_phone  : conversationDetail.To,
        recipient_phone_1: conversationDetail.From,
        recipient_id     : recipient_id,
        template_id      : "",
        type             : (conversationDetail.RecepientRoleID == 3) ? 1: 0,
        template         : this.form.template_data,
        Campaign_ID      : this.$store.getters.getActiveCampaignId,
      };
      console.log(data);
      axios
        .post("send-custom-sms", data)
        .then((resp) => {
            if (resp.data.success) {
              this.sendCustomMessageProcessing=false;
                alert(resp.data.message);
                
                this.$refs.observer.reset()
                // this.getData();
                  this.scrollToBottom();
                this.chats.push({
                  event_log_ID : 1,
                  sender_name: this.$store.getters.getLoggedInUserName,
                  CreatedDate: this.$moment().format(),
                  Body: this.form.template_data
                })
                this.scrollToBottom();
              
                this.form.template_data = "";
                
                // Reset Textarea
                this.$refs.textareaChat.style.height = "auto";
                this.$refs.themeContent.style.paddingBottom = this.$refs.textareaChat.offsetHeight + 60 + "px";
            }
        })
        .catch((err) => {
          alert("Something went wrong! We couldn't process your request. ");
          this.sendCustomMessageProcessing=false;
          console.log(err);
        });
    },
    
    getTemplates() {
      window.scrollTo(0, 0);
      let conversationDetail = this.$store.getters.getConversationDetails;
      console.log(conversationDetail);
      let active_campaign_ID = this.$store.getters.getActiveCampaignId;
      
      let recipient_id =  (conversationDetail.RecepientRoleID == 3) ? conversationDetail.RecepientID: '';
      let phone = conversationDetail.To.replace(/\W/g, "");
      let hcp_id =  (conversationDetail.RecepientRoleID == 3) ? conversationDetail.RecepientID : conversationDetail.PhyscianId;
     
      let data = {
        campaign_id: active_campaign_ID,
        phone: phone,
        hcp_id:          hcp_id,
        type             : (conversationDetail.RecepientRoleID == 3) ? 1: 0,
       recipient_other_id: recipient_id,
      };
      console.log(data);
      axios
        .get("/templates", {
          params: data,
        })
        .then((resp) => {
          this.templates = resp.data.data.filter(template => template.custom_body === false);
        });

      this.templateModal = !this.templateModal;
    },

    scrollToBottom() {
 
     
        var box = document.body;
        var height = box.clientHeight;
        window.scrollTo(0, height);
       
    },
    showModal() {
      this.$refs["preApprovedTemplateModal"].show();
      this.getTemplates();
    },
    getData() {
      window.clearTimeout(this.timeoutHandle);
      this.timeoutHandle = setTimeout(() => {
        this.page = 1;
        this.chats = [];
        this.infiniteId += 1;
      }, 500);
    },
    
    infiniteHandler($state) {
      if(this.ajaxreq){
        this.ajaxreq.cancel();
      }
      this.ajaxreq = axios.CancelToken.source();
      this.state = $state;
      let details         = this.$store.getters.getConversationDetails
      let conversation_id = details.SMSConversationID

      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/converstations/" + conversation_id, {
          cancelToken: this.ajaxreq.token ,
          params: {
            page: this.page,
            limit: this.limit,
          },
        })
        .then(({ data }) => {
          if (data.data.length) {
            if (data.data.length === this.limit) {
              this.page += 1;
            } else {
              $state.complete();
            }
            this.chats.unshift(...data.data.reverse());
            // this.chats.push(...data.data);
            this.filterconverstions = this.chats;
            this.loading = false;
            //this.scrollToBottom();
            for (var index in data.data) {
              if (data.data[index].ReadAt == "") {
                this.updateReadAtDate();
                break;
              }
            }
            for (var idx in data.data) {
              if (data.data[idx].is_archived) {
                this.msgDeleted = true;
              }
            }
         
            data.data.map((data) => {
              if (
                data.attach1_type === ".jpg" ||
                data.attach1_type === ".gif" ||
                data.attach1_type === ".png"
              ) {
                this.onImgLoad();
              } else {
                //this.scrollToBottom();
              }
            });
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    onImgLoad() {
      //this.scrollToBottom();
    },
    updateReadAtDate() {
      let details         = this.$store.getters.getConversationDetails;
      let conversation_id = details.SMSConversationID
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios.put("/read-at/" + conversation_id).then((resp) => {
        console.log(resp.data.data);
      });
    },
    toggleFunc(el) {
      // var el = this;
      // var target = el.getAttribute('data-target');
      console.log(el);
    },
    selectedTemplate(reference, index) {
      var list = document.querySelectorAll("li"),
        i = list.length;

      while (i--) {
        list[i].classList.remove("selected");
      }
      this.selectedTemplateData = this.templates[index];
      document.getElementById(reference).classList.add("selected");
    },
    closeTemplateSelected() {
      this.templateModal = this.selectedTemplateData = false;
      var list = document.querySelectorAll("li"),
        i = list.length;

      while (i--) {
        list[i].classList.remove("selected");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  body{
    min-height: 100vh;
  }
  .theme-main-content.optedOut{
    padding-bottom: 30px;
    padding-top: 30px;
    margin-top: 132px;
    @include responsive(col-md){
      margin-top: 100px;
    }
  }
  .error{
  color: red;

}
</style>