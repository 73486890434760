<template>
    <section></section>
</template>
<script>
import oktaConfig from '../config/okta'
import pingConfig from '../config/ping'
export default {
    data(){
        return{
            okta: oktaConfig,
            ping: pingConfig
        }
    },
    methods:{
        changeRoute(){
            this.$router.push('/login/validation') 
        },
        async getAccessToken(){
            var config = (localStorage.getItem('sso_type') === 'okta')? this.okta : this.ping;
            await this.$auth.accessToken(config);
            this.changeRoute();
        }
    },
    mounted(){
        this.getAccessToken();
    }
}

</script>