<template>
  <div class="sidebar-default">
    <div class="px-2 mb-2">
      <HistorySubNav></HistorySubNav>
    </div>
    <div class="sidebar-body"  @click="closeMenuFunc" >
      <CallTabView></CallTabView>
    </div>
  </div>

</template>

<script>
import HistorySubNav from '@/components/navbar/HistorySubNav'
import CallTabView  from "@/components/Call/CallTabView.vue";
export default {
  name: "CallLogLayout",
  components: {
    HistorySubNav,
    CallTabView
  },
  methods:{
    closeMenuFunc(){
      
    }
  }
}
</script>

<style scoped>
  /* .sidebar-body{
    height: 100vh;
    overflow: scroll;
  } */
</style>