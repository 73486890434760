<template>
  <div class="sidebar-dynamic" v-if="active">
    <template v-if="id === 1">
      <div class="sidebar-main pb-0 sidebar-dynamic-main">
        <div class="sidebar-main-header mb-0">
          <div class="sidebar-main-title">
            <h4 class="bold text-gray-500 mb-05">Assets</h4>
            <p>List of Assets</p>
          </div>
          <button
            type="button"
            class="sidebar-main-icon btn-icon btn-gray-100"
            @click="$emit('navtoggle')"
          >
            <feather type="x" size="15"></feather>
          </button>
        </div>
      </div>
      <div class="sidebar-body">
        <div class="sidebar-dynamic-search">
          <select
            class="form-control form-control-sm"
            v-model="selectedCampaignID"
            @change="GetTheAssetsBasedOnTheProduct"
          >
            <option
              :value="campaigns[val].CampaignID"
              v-for="(index, val) in campaigns"
              :key="index"
            >
              {{ campaigns[val].product_name }}
            </option>
          </select>
        </div>
        <ul class="chat-main">
          <li v-for="(asset, index) in assets" :key="index">
            <div class="chat-box">
              <div
                class="chat-box-profile chat-box-profile-icon bg-success-light text-success"
              >
                <feather type="star" size="15"></feather>
              </div>
              <div class="chat-box-details">
                <h5>{{ asset.asset_name }}</h5>
                <h6>{{ asset.asset_description }}</h6>
        
              </div>
              <div class="chat-box-date-status">
                <a
                  class="sidebar-main-icon btn-icon btn-gray-100"
                  target="__blank"
                  :href="asset.file_url"
                >
                  <feather type="download" size="15"></feather>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="id === 2">
      <div class="sidebar-main pb-0 sidebar-dynamic-main">
        <div class="sidebar-main-header mb-0">
          <div class="sidebar-main-title">
            <h4 class="bold text-gray-500 mb-05">Pre-Approved Templates</h4>
            <p>List of Pre-Approved Templates</p>
          </div>
          <button
            type="button"
            class="sidebar-main-icon btn-icon btn-gray-100"
            @click="$emit('navtoggle')"
          >
            <feather type="x" size="15"></feather>
          </button>
        </div>
      </div>
      <div class="sidebar-body">
        <div class="sidebar-dynamic-search">
          <select
            class="form-control form-control-sm"
            v-model="selectedCampaignID"
            @change="GetTheTemplateBasedOnTheProduct"
          >
            <option
              :value="campaigns[val].CampaignID"
              v-for="(index, val) in campaigns"
              :key="val"
            >
              {{ campaigns[val].product_name }}
            </option>
          </select>
        </div>
        <div class="chat-main">
          <ul class="template-modal-list">
            <li
              v-for="(template, index) in templates"
              :id="'template' + index"
              :key="index"
              :class="{ selected: template.templateSelected }"
              @click="selectedTemplate('template' + index, index)"
            >
              <div class="template-modal-body">
                <div class="template-modal-body-title font-weight-bold">
                  {{ template.Template_Name }}
                  {{ template.templateSelected }}
                </div>
                <p>
                  <span>{{ template.Template_body }}</span>
                </p>
              </div>
              <!-- Add class active-assets/active-attachments accordingly for arrow effect -->
              <div
                class="template-modal-footer active-assets"
                v-if="template.show_assets && template.Asset"
              >
                <div class="template-modal-footer-top">
                  <button class="template-modal-btn assets-btn text-gray-400">
                    Assets
                    <span class="badge badge-info">{{
                      JSON.parse(template.Asset).length
                    }}</span>
                  </button>
                </div>
                <div class="template-modal-footer-bottom">
                  <ul class="font-sm list list-styled list-styled-gray-400">
                    <li
                      v-for="(value, idx) in JSON.parse(template.Asset)"
                      :key="idx"
                    >
                      <a :href="value.file_url">{{ value.Asset_Name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="floating-btn">
                <button
                  @click="sendPreApprovedTemplates"
                  class="btn btn-primary btn-sm"
                >
                  <feather type="send" size="18"></feather>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    active: Boolean,
    id: Number,
  },
  created() {
    this.$store.commit("initialize");
  },
  data() {
    return {
      currentDate: '',
      assets: [],
      selectedCampaignID: "",
      campaigns: "",
      templates: [],
    };
  },
  mounted() {
    this.getCampaignDetails();
    this.GetTheAssetsBasedOnTheProduct();
    this.GetTheTemplateBasedOnTheProduct();
    this.currentDateFunc();
  },
  methods: {
    currentDateFunc(){
      this.currentDate = new Date();
    },
    getCampaignDetails() {
      this.campaigns = this.$store.getters.getAllCampaigns;
      this.selectedCampaignID = this.$store.getters.getActiveCampaignId;
    },
    GetTheAssetsBasedOnTheProduct() {
      var token = localStorage.getItem("access_token");
      var campaignId = this.$store.getters.getActiveCampaignId;
      console.log(campaignId);
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/assets", {
          params: {
            campaign_id: this.selectedCampaignID,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.assets = resp.data.data;
        });
    },
    GetTheTemplateBasedOnTheProduct() {
      let conversationDetail = this.$store.getters
        .getconversationOpeningDetailsData;
      let active_campaign_ID = this.selectedCampaignID;
      let phone = conversationDetail.phone_number;
      let hcp_id = conversationDetail.prescriber_id;
      let recipient_other_id = conversationDetail.recipient_id;
      let data = {
        campaign_id: active_campaign_ID,
        phone: phone,
        hcp_id: hcp_id,
        type: conversationDetail.type,
        recipient_other_id: recipient_other_id,
      };
      axios
        .get("/gettemplates", {
          params: data,
        })
        .then((resp) => {
          this.templates = resp.data.data;
        });

      this.templateModal = !this.templateModal;
    },
    sendPreApprovedTemplates() {
      let conversationDetail = this.$store.getters
        .getconversationOpeningDetailsData;
      let active_campaign_ID = this.selectedCampaignID;
      let recipient_id = conversationDetail.recipient_id;
      let phone = conversationDetail.phone_number;
      let hcp_id = conversationDetail.prescriber_id;
      let recipient_name = conversationDetail.recipient_name;

      let data = {
        recipient_name: recipient_name,
        recipient_id: recipient_id,
        physcian_id: hcp_id,
        type: conversationDetail.type,
        template_id: this.selectedTemplateData.Template_ID,
        assets: this.selectedTemplateData.Asset,
        template: this.selectedTemplateData.Template_body,
        recipient_phone: phone,
        Campaign_ID: active_campaign_ID,
      };
      console.log(data);

      axios
        .post("/send-sms", data)
        .then((resp) => {
          if (resp.data.success) {
            this.getData();
            this.closeTemplateSelected();
            alert(resp.data.message);
            this.$refs["preApprovedTemplateModal"].hide();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.sidebar-dynamic-search{
  padding: 10px $sidebar-gutter;
  @include responsive(col-md) {
    padding: 5px $sidebar-gutter-sm;
  }
}
.sidebar-dynamic{
  .chat-main{
    height: calc(100vh - 163px);
    @include responsive(col-md) {
      height: calc(100vh - 133px);
    }
    .template-modal-list{
      padding: 15px 40px;
      @include responsive(col-md) {
        padding: 15px 20px;
      }
    }
  }
  .sidebar-main-title{
    h4{
      @include responsive(col-md) {
        font-size: 18px;
      }
    }
  }
}
</style>