<template>
  <div>
    <div class="search-box sidebar-main-search px-2 pb-2">
      <form class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="'Search Contact'"
          v-model="searchQuery"
          ref="search_input"
          @keyup="keyUpeventGetData"
        />
        <!-- @keyup="getData" -->
        <div class="input-group-append">
          <button class="btn" type="button">
            <feather type="search" size="18" @click="getData"></feather>
          </button>
        </div>
      </form>
    </div>
    <ul class="chat-main scrollbox scrollbox-lg">
      <li v-for="(contact, index) in contacts" :key="index">
        <ContactBox
          :dataObj="contact"
          @openCallModel="openCallModel"
          @getConversationScreen="getConversationScreen"
        />
      </li>
      <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteContactHandler"
      >
        <div slot="no-more">No more contact</div>
        <div slot="no-results">No results contact</div>
      </infinite-loading>
    </ul>
    <!-- Modal Contact -->
    <b-modal id="modal-4" hide-footer centered title="New Contact">
      <form action="" @submit.prevent="submitForm">
        <div class="form-group">
          <p class="text-gray-400 font-weight-bold">Prescriber</p>
          <autocomplete
            ref="prescriber"
            @submit="handleSubmit"
            :search="search"
            :debounceTime="500"
            :get-result-value="getResultValue"
            placeholder="Search for a Prescriber"
            aria-label="Search for a Prescribers"
          ></autocomplete>
        </div>
        <div
          class="form-group floating-form form-autocomplete"
          :class="{ 'form-autocomplete-shown': filterOthers.length > 0 }"
        >
          <input
            type="text"
            placeholder="Please enter Recipient name"
            class="form-control"
            :disabled="perscriberInactive"
            v-model="form.recipient.name"
            @change="onChangeOthers()"
            @keyup="checkOthers($event)"
            autocomplete="off"
          />
          <label for="">Recipient</label>
          <ul class="form-autocomplete-list" v-if="filterOthers.length > 0">
            <li
              v-for="(other, index) in filterOthers"
              :key="index"
              @click="setOthersValue(other)"
            >
              {{ other.name }}
            </li>
          </ul>
        </div>
        <div class="form-group floating-form">
          <input
            type="text"
            placeholder="Please enter the phone number"
            class="form-control"
            v-model="form.phone_number"
            ref="phone_number"
            :readonly="perscriberInactive"
            @change="checkTemplates"
            @focus="checkTemplates"
            autocomplete="off"
          />
          <label for="">Phone Number</label>
          <p
            class="label-error"
            v-if="phoneNumberError"
            v-show="form.phone_number != ''"
          >
            Enter a valid phone number
          </p>
        </div>

        <div class="row">
          <div class="col-8 offset-2">
            <button
              :disabled="processing"
              class="btn btn-primary btn-block btn-rounded"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Close
        </b-button>
      </template>
    </b-modal>

    <!-- New Number -->
    <b-modal
      id="enterNumber"
      hide-footer
      hide-header
      ref="enterNumber"
      centered
    >
      <p class="font-weight-bold text-black text-center">
        Please enter the number
      </p>
      <div class="form-group">
        <input
          type="text"
          placeholder="Enter the number"
          v-model="promptNumber"
          @change="phoneNumberValidation()"
          @keyup="phoneNumberValidation()"
          class="form-control"
        />
        <p
          class="label-error"
          v-if="phoneNumberError"
          v-show="promptNumber != ''"
        >
          Enter a valid phone number
        </p>
      </div>
      <div class="row">
        <div class="col-6 text-right">
          <button
            class="btn btn-md btn-success"
            @click="createConversation()"
            :disabled="phoneNumberError"
            type="button"
          >
            Proceed
          </button>
        </div>
        <div class="col-6 text-left">
          <button
            class="btn btn-md btn-danger"
            type="button"
            @click="hideModal('enterNumber')"
          >
            Cancel
          </button>
        </div>
      </div>
    </b-modal>
    <CallModel :data="callData"></CallModel>
  </div>
</template>
<script>
import axios from "axios";
import ContactBox from "@/components/Contact/ContactBox.vue";
import CallModel from "@/components/Call/CallModal";
export default {
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      chatItems: [
        {
          avatar: "@/assets/img/user.png",
          name: "Josephin water",
          msg:
            "Hi, i am josephin. How are you.. ! There are many variations of passages.",
          date: "22/10/19",
          path: "/chat",
        },
      ],

      start: 10,
      page: 1,
      limit: 10,
      callDetails: "",
      chatTab: 0,
      loading: true,
      isDrawer: false,
      messages: [],
      filterMessages: [],
      conversations: false,
      showSearch: false,
      searchQuery: "",
      timeoutHandle: "",
      contacts: [],
      infiniteId: +new Date(),

      processing: false,
      form: {
        phone_number: "",
        physician_id: "",
        prescriber_id: "",
        asset: "",
        template_id: "",
        template: "",
        recipient: {
          id: "",
          name: "",
        },
        type: 1,
      },
      perscriberInactive: true,
      phoneNumberError: true,
      filterOthers: [],
      others: [],
      callData: false,
      promptNumber: "",
      conversationDetails: null,
      conversationData: null,
      invalidNumber: false,
      ajaxreq: null,
      currentDevice: "",
    };
  },
  created() {
    this.$store.commit("initialize");
  },
  methods: {
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    keyUpeventGetData() {
      let currentDevice = this.getDeviceInfo();
      console.log(currentDevice);
      if (currentDevice != "/Android/i") {
        window.clearTimeout(this.timeoutHandle);
        this.timeoutHandle = setTimeout(() => {
          this.page = 1;
          this.contacts = [];
          this.infiniteId += 1;
        }, 500);
      }
    },
    getData() {
      this.page = 1;
      this.contacts = [];
      this.infiniteId += 1;
    },

    // checkOthers(event) {
    //   this.form.recipient.id = "";
    //   var value = event.target.value;
    //   if (this.others.length > 0 && value) {
    //     this.searchothers(value);
    //   } else {
    //     this.filterOthers = [];
    //   }
    // },
    getDeviceInfo() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /Windows Phone/i,
        /BlackBerry/i,
        /Macintosh/i,
      ];
      let device;
      toMatch.some((toMatchItem) => {
        if (navigator.userAgent.match(toMatchItem)) {
          device = toMatchItem;
          return toMatchItem;
        } else return false;
      });
      return device;
    },
    createConversation() {
      let phone_number = this.promptNumber;
      if (this.phoneNumberError) {
        return false;
      } else {
        axios
          .post("/conversation", {
            hcp_id: this.conversationData.prescriber_id,
            phone_number: phone_number,
          })
          .then((resp) => {
            this.conversationDetails.SMSConversationID =
              resp.data.data.ConversationID;
            this.conversationDetails.To = phone_number;
            this.$store.commit(
              "setConversationDetails",
              this.conversationDetails
            );
            this.$router.push("/chat/all");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async getConversationScreen(data) {
      console.log(data);
      console.log("data");
      let details = {
        SenderID: this.$store.getters.getLoggedInID,
        RecepientRoleID: data.role_id,
        RecepientID: data.phone_extension_id,
        To: data.phone,
        From: this.$store.getters.getLoggedInVirtualNumber,
        recepient_name: data.user_name,
        sender_name: this.$store.getters.getLoggedInUserName,
        PhyscianId: data.prescriber_id,
        SMSConversationID: data.ConversationID,
      };

      this.conversationDetails = details;
      this.conversationData = data;

      if (!data.phone || !data.ConversationID || data.phone == "undefined") {
        this.$refs["enterNumber"].show();
        this.promptNumber = data.phone;
        this.phoneNumberValidation();
      } else {
        this.$store.commit("setConversationDetails", details);
        this.$router.push("/chat/all");
      }
    },
    openCallModel(data) {
      console.log(data);
      let callData = {
        contact_name: data.user_name,
      };
      if (!data.phone) {
        var phone_number = prompt("Please enter the number", "");

        if (this.validatePhone(phone_number)) {
          callData.phone_number = phone_number;
          // api call to update the number;
          axios
            .put("prescribers", {
              hcp_id: data.prescriber_id,
              phone_number: phone_number,
            })
            .then((resp) => {
              console.log(resp.data);
              console.log(callData);
              this.callData = callData;
              this.$bvModal.show("modal-6");
            });
        } else {
          alert("Invalid Phone number!");
        }
      } else {
        callData.phone_number = data.phone;
        console.log(callData);
        this.callData = callData;
        this.$bvModal.show("modal-6");
      }
    },
    validatePhone(phone_number) {
      console.log("validation " + phone_number);
      var phone = phone_number.replace(/\W/g, "");
      this.promptNumber = phone;
      if (!Number(phone)) {
        this.phoneNumberError = true;
        return false;
      }
      if (phone.length < 10) {
        this.phoneNumberError = true;
        return false;
      } else if (phone.length == 10) {
        this.phoneNumberError = false;
        return true;
      } else if (phone.length == 11) {
        var res = phone.charAt(0);
        if (res != 1) {
          this.phoneNumberError = true;
          return false;
        } else {
          this.phoneNumberError = false;
          return true;
        }
      } else {
        this.phoneNumberError = true;
        return false;
      }
    },
    infiniteContactHandler($state) {
      if (this.ajaxreq) {
        this.ajaxreq.cancel();
      }
      this.ajaxreq = axios.CancelToken.source();
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/getcontacts", {
          cancelToken: this.ajaxreq.token,
          params: {
            start: this.contacts.length,
            search_query: this.searchQuery,
            limit: this.limit,
            page: this.page,
          },
        })
        .then(({ data }) => {
          if (data.data.length) {
            if (data.data.length === this.limit) {
              this.page++;
              console.log(this.page);
            } else {
              $state.complete();
            }
            this.contacts.push(...data.data);
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    submitForm() {
      const { physician_id, phone_number, recipient } = this.form;
      console.log({
        prescriber_id: physician_id.prescriber_id,
        phone_number: phone_number,
        recipient_name: recipient.name,
      });
      axios
        .post("/contacts", {
          prescriber_id: physician_id.prescriber_id,
          phone_number: phone_number,
          recipient_name: recipient.name,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            alert(resp.data.message);
            // close the modal
            this.form.recipient = "";
            this.form.phone_number = "";
            this.$bvModal.hide("modal-4");
          }
        });
    },
    getContacts() {
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/getcontacts", {
          params: {
            start: 1,
            search_query: this.searchQuery,
            limit: this.limit,
            page: 1,
          },
        })
        .then((resp) => {
          console.log(resp);
          this.contacts = resp.data.data;
        });
    },
    checkOthers(event) {
      this.form.recipient.id = "";
      var value = event.target.value;
      if (this.others.length > 0 && value) {
        this.searchothers(value);
      } else {
        this.filterOthers = [];
      }
    },
    setOthersValue(result) {
      this.filterOthers = [];
      this.form.phone_number = result.phone;
      this.form.recipient.name = result.name;
      this.form.recipient.id = result.id;
      this.$refs.phone_number.focus();
    },
    searchothers(value) {
      this.filterOthers = this.others.filter((other) => {
        return value
          .toLowerCase()
          .split(" ")
          .every((v) => other.name.toLowerCase().includes(v));
      });
    },
    onChangeOthers() {
      if (!this.form.recipient.id) {
        this.form.phone_number = "";
      }
    },
    changeType() {
      this.form.phone_number = this.form.template_id = this.form.template = this.form.physician_id = this.form.asset = this.form.recipient.name = this.form.recipient.id = this.oldPhoneNumber = this.$refs.prescriber.value =
        "";

      // Reset fields
      this.perscriberInactive = true;
      this.showAssets = false;
      this.templates = this.assets = [];
    },
    phoneNumberValidation() {
      let phone = this.promptNumber;
      console.log("validation " + this.promptNumber);
      phone = phone.replace(/\W/g, "");
      this.promptNumber = phone;
      if (!Number(phone)) {
        this.phoneNumberError = true;
        return false;
      }
      if (phone.length < 10) {
        this.phoneNumberError = true;
        return false;
      } else if (phone.length == 10) {
        this.phoneNumberError = false;
        return true;
      } else if (phone.length == 11) {
        var res = phone.charAt(0);
        if (res != 1) {
          this.phoneNumberError = true;
          return false;
        } else {
          this.phoneNumberError = false;
          return true;
        }
      } else {
        this.phoneNumberError = true;
        return false;
      }
    },
    checkTemplates() {
      if (
        this.form.phone_number != this.oldPhoneNumber ||
        this.form.phone_number == ""
      ) {
        this.form.template = this.oldPhoneNumber = this.form.template_id = "";
        this.templates = this.assets = [];
      }

      this.phoneNumberValidation();
    },
    getResultValue(result) {
      return result.HCP_name;
    },
    async search(input) {
      if (input.length < 2) {
        return [];
      }
      let token = this.$store.state.access_token;
      axios.defaults.headers.common["Authorization"] = token;
      let prescribers = await axios.get("/prescribers", {
        params: {
          name: input,
        },
      });
      return prescribers.data.data.filter((prescriber) => {
        return (
          prescriber.first_name.toLowerCase().startsWith(input.toLowerCase()) ||
          prescriber.last_name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    async handleSubmit(result) {
      this.form.physician_id = result;
      this.form.prescriber_id = result.prescriber_id;
      this.perscriberInactive = false;

      let token = this.$store.getters.getAccessToken;
      axios.defaults.headers.common["Authorization"] = token;
      if (this.form.type === 0) {
        this.form.phone_number = result.Phone_Number;
        this.$refs.phone_number.focus();
      } else {
        let othersRes = await axios.get("/other-contact-lists", {
          params: {
            prescriber_id: result.prescriber_id,
          },
        });
        this.others = othersRes.data.data;
      }
    },
  },
  mounted() {},
  components: {
    ContactBox,
    CallModel,
  },
};
</script>