const config = {
    authority: process.env.VUE_APP_PING_AUTHORITY,
    clientId: process.env.VUE_APP_PING_CLIENTID,
    clientSecret: process.env.VUE_APP_PING_CLIENTSECRET,
    redirectUri: window.location.origin +"/implicit/callback",
    responseType: "code",
    scope: "openid profile email",
    type:'ping',
    metadata: {
      issuer: process.env.VUE_APP_PING_ISSUER,
      authorization_endpoint: process.env.VUE_APP_PING_AUTHORIZATION_ENDPOINT,
      token_endpoint: process.env.VUE_APP_PING_TOKEN_ENDPOINT,
      userinfo_endpoint: process.env.VUE_APP_PING_USERINFO_ENDPOINT
    }
  }
  
  export default config