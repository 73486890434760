<template>
  <!-- Content Begin -->
  <main class="theme-main theme-main-full">
    <div class="theme-main-body">
        <div class="p-2">
            <button type="button" onclick="window.history.back()" class="btn-light btn-icon mb-3">
                <feather type="chevron-left" size="18"></feather>
            </button>
            <div class="row">
                <div class="col-md-12 mb-2 text-center">
                    <div class="bg-white br-15 p-3">
                        <div class="avatar avatar-xl avatar-rounded text-center mb-1">
                            <img src="@/assets/img/user.png" alt="" />
                        </div>
                        <h4 class="text-info bold mb-2">{{ user.name }}</h4>
                        <div class="row">
                            <div class="col-8 offset-2 col-md-12 offset-md-0">
                                <!-- <ul class="row">
                                    <li class="col-6">
                                        <button class="sidebar-main-icon btn-icon btn-light-info">
                                            <feather type="message-square" size="18"></feather>
                                        </button>
                                        <h6 class="text-info">
                                            Message
                                        
                                        </h6>
                                    </li>
                                    <li class="col-6">
                                        <button @click="openCallModel()" class="sidebar-main-icon btn-icon btn-light-success" v-b-modal.modal-6>
                                            <feather type="phone" size="18"></feather>
                                        </button>
                                        <h6 class="text-success">Voice Call</h6>
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="bg-white br-15 p-3">
                        <div class="title">
                            <h5>Contact Info</h5>
                        </div>
                        <ul class="list list-lined">
                            <li class="row">
                                <div class="col-6">
                                    <p class="bold">Name</p>
                                </div>
                                <div class="col-6 text-right">
                                    <p>{{ user.name }}</p>
                                </div>
                            </li>
                            <li class="row">
                                <div class="col-6">
                                    <p class="bold">Mobile No</p>
                                </div>
                                <div class="col-6 text-right">
                                    <p>{{ user.phone }}</p>
                                </div>
                            </li>
                            <li class="row">
                                <div class="col-6">
                                    <p class="bold">Speciality</p>
                                </div>
                                <div class="col-6 text-right">
                                    <p>{{ user.speciality }}</p>
                                </div>
                            </li>
                             <li class="row">
                                <div class="col-6">
                                    <p class="bold">Credential</p>
                                </div>
                                <div class="col-6 text-right">
                                    <p>{{ user.credential }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CallModel :data="callData" ></CallModel>
  </main>
  <!-- Content End -->
</template>

<script>
import CallModel from '@/components/Call/CallModal'
export default {
    data(){
        return{
            callData:false,
            user:{
                name:"",
                phone:"",
                prescriberId:"",
                credential:"",
                speciality:"",
                Account_identifier:""
            }
        }
    },
    components:{
        CallModel
    },
    created() {
    this.$store.commit("initialize");
  },
    methods:{
    openCallModel(data){
      console.log(data)
      let callData = {
        contact_name: data.user_name
      }
      if(!data.phone){
        var phone_number = prompt("Please enter the number", "+17325177793");
        callData.phone_number = phone_number
        // api call to update the number;
      }else{
         callData.phone_number = data.phone
      }
      console.log(callData)
      this.callData = callData;
      this.$bvModal.show('modal-6')
      
    },
    getUserInfo(){
        // fetch from api
        console.log(this.$store.getters.getcontactDetails)
        this.user.phone = this.$store.getters.getcontactDetails.phone;
        this.user.name = this.$store.getters.getcontactDetails.user_name;
        this.user.credential = this.$store.getters.getcontactDetails.designation;
        this.user.speciality = this.$store.getters.getcontactDetails.PCYC_Specialty__c;
        this.user.Account_identifier = this.$store.getters.getcontactDetails.Account_identifier;
    }
    },
    mounted(){
        
        this.getUserInfo()
    }
};
</script>

<style lang="scss" scoped>
.theme {
  &-main-full {
    .theme-main-body {
      height: 100vh;
    }
  }
}
</style>