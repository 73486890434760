<template>
  <!-- Content Begin -->
  <main class="theme-main">
    <div class="theme-main-body">
      <div class="theme-main-header theme-main-header-lg">
       
        <div class="theme-main-header-left">
          <router-link
            to="/"
            class="btn-inverse-light btn-icon mr-1"
            data-toggle="tooltip"
            data-title="tooltip"
          >
            <feather type="chevron-left" size="18"></feather>
          </router-link>
          <div class="avatar avatar-lg theme-main-header-avatar mr-2">
            <img src="@/assets/img/user.png" alt="" />
          </div>
          <div class="theme-main-header-details">
            <h5 class="mb-05" v-if="this.$store.getters.getconversationOpeningDetailsData.type == 1">
            {{ this.$store.getters.getconversationOpeningDetailsData.HCP_name  }} -  {{$store.getters.getconversationOpeningDetailsData.recipient_name }}
            </h5>
            <h5 v-else>
               {{ this.$store.getters.getconversationOpeningDetailsData.HCP_name  }} 
            </h5>
            <p>{{ this.$store.getters.getconversationOpeningDetailsData.phone_number }}</p>
          </div>
         
        </div>
        <div class="theme-main-header-col-right">
          <div class="theme-main-header-right">
            <ul class="list-inline">
              <!-- <li>
                <a
                  href="#"
                  class="btn-inverse-light btn-icon"
                  data-toggle="tooltip"
                  data-title="tooltip"
                >
                  <feather type="phone" size="18"></feather>
                </a>
              </li> -->
              <li>
                <a
                  href="#"
                  class="btn-inverse-light btn-icon"
                  data-toggle="tooltip"
                  data-title="tooltip"
                >
                  <feather type="more-vertical" size="18"></feather>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="search-box theme-main-header-search"
          :class="{ active: headerSearch }"
        >
          <form class="input-group">
            <div class="input-group-prepend">
              <button class="btn" type="button">
                <feather type="search" size="18"></feather>
              </button>
            </div>
            <input type="text" class="form-control" placeholder="Search...." />
            <div class="input-group-append">
              <button
                @click.prevent="headerSearch = false"
                class="btn"
                type="button"
              >
                <feather type="x" size="15"></feather>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="theme-main-content theme-main-content-lg" id="chatBody">
        <ul class="chat-list">
          <infinite-loading direction="top" @infinite="infiniteHandler">
            <!-- <div slot="spinner"></div> -->
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
          <li
            v-for="(chat, index) in chats"
            :key="index"
            :class="chat.event_log_ID ? 'replies' : 'sent'"
          >
            <Message
              avatar="@/assets/img/user.png"
              :name="chat.sender_name"
              :thumbnail1="
                chat.attach1_type === '.jpg' ||
                chat.attach1_type === '.gif' ||
                chat.attach1_type === '.png'
                  ? chat.attach1
                  : ''
              "
              :thumbnail2="
                chat.attach2_type === '.jpg' ||
                chat.attach2_type === '.gif' ||
                chat.attach2_type === '.png'
                  ? chat.attach2
                  : ''
              "
              :vcard="chat.attach1_type === '.vcf' ? chat.attach1 : ''"
              :dateTime="chat.CreatedDate"
              :conversations="chat.Body"
            />
          </li>
        </ul>
      </div>
      <b-modal id="modal-2" ref="preApprovedTemplateModal" button-size="sm">
        <template #modal-title> Pre Approved Templates </template>
       
            <ul class="template-modal-list">
                <li>
                  <select class="form-control form-control-sm" v-model="selectedCampaignID" @change="GetTheTemplateBasedOnTheProduct">
                
                    <option :value="campaigns[val].CampaignID" v-for="(index,val) in campaigns" :key="val">{{ campaigns[val].product_name }}</option>
                  </select>
                </li>
                <li
                    v-for="(template, index) in templates"
                    :id="'template' + index"
                    :key="index"
                    :class="{ selected: template.templateSelected }"
                    @click="selectedTemplate('template' + index, index)"
                >
                    <div class="template-modal-body">
                    <div class="template-modal-body-title">
                        {{ template.Template_Name }} {{ template.templateSelected }}
                    </div>
                    <p>
                        <span>{{ template.Template_body }}</span>
                    </p>
                    </div>
                    <!-- Add class active-assets/active-attachments accordingly for arrow effect -->
                    <div
                    class="template-modal-footer active-assets"
                    v-if="template.show_assets && template.Asset"
                    >
                    <div class="template-modal-footer-top">
                        <button class="template-modal-btn assets-btn text-gray-400">
                        Assets
                        <span class="badge badge-info">{{
                            JSON.parse(template.Asset).length
                        }}</span>
                        </button>
                    </div>
                    <div class="template-modal-footer-bottom">
                        <ul class="font-sm list list-styled list-styled-gray-400">
                            <li v-for="(value,index) in JSON.parse(template.Asset)" :key="index">
                                <a :href="value.file_url">{{ value.Asset_Name }}</a>
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div class="floating-btn">
                        <button @click="sendPreApprovedTemplates" class="btn btn-primary btn-sm"><feather type="send" size="18"></feather></button>
                    </div>
                </li>
            </ul>
        <template #modal-footer="{ cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->

          <b-button size="sm" variant="danger" @click="cancel()">
            Close
          </b-button>
          <!-- Button with custom close trigger value -->
        </template>
      </b-modal>
      <form class="theme-main-footer" :class="{error: sensitiveMsg}" @submit.prevent="sendCustomMessage">
        <div class="theme-main-footer-error">
          <p>Inappropriate message</p>
        </div>
        <div class="theme-main-footer-btns">
          <!-- <a
            href="#"
            class="btn-light btn-icon"
           
          >
            <feather type="smile" size="18"></feather>
          </a> -->
          <a
            href="#"
            class="btn-light btn-icon"
            v-b-modal.modal-2
            @click="showModal"
           
          >
            <feather type="folder" size="18"></feather>
          </a>
        </div>
        <div class="theme-main-footer-writer">
          <input placeholder="Write your message..."
           @keyup="checkSensitive"
          v-model="form.template_data" />
        </div>
        <div class="theme-main-footer-btns">
          <button
            type="submit"
            :disabled="sensitiveMsg"
            class="btn-light btn-icon"
            v-b-tooltip.hover
            title="Send SMS"
          >
            <feather type="send" size="18"></feather>
          </button>
        </div>
      </form>
    </div>

    <b-modal id="audio-modal" modal-header="false"> </b-modal>
  </main>
  <!-- Content End -->
</template>

<script>
import axios from "axios";
import Message from "@/components/Chat/Message";
import Pusher from "pusher-js";
export default {
  components: {
    Message,
  },
  created(){
    this.$store.commit('initialize');
  },
  mounted(){
     this.subscribe();
     this.getProducts();
  },
  data() {
    return {
      state: {},
      templates: [],
      msg: "Loading Conversation",
      url: `<img src="/preloader.gif" width="90"/>`,
      loading: true,
      recepient_name: "",
      page: 1,
      limit: 10,
      chats: [],
      name: "",
      form: {
        template_data: "",
      },
      isLoaded: false,
      templateModal: false,
      selectedTemplateData: false,

      // Header
      headerSearch: false,
      selectedCampaignID:'',
      campaigns: '',
      sensitiveMsg: false,
      filterconverstions: []
    };
  },

  methods: {
      getProducts(){
        this.campaigns = this.$store.getters.getAllCampaigns; 
        this.selectedCampaignID = this.$store.getters.getActiveCampaignId; 
      },
      GetTheTemplateBasedOnTheProduct(){
          let conversationDetail = this.$store.getters.getconversationOpeningDetailsData;
          let active_campaign_ID = this.selectedCampaignID;
          let phone = conversationDetail.phone_number;
          let hcp_id =  conversationDetail.prescriber_id;
          let recipient_other_id  = conversationDetail.recipient_id;
          let data = {
            campaign_id: active_campaign_ID,
            phone: phone,
            hcp_id: hcp_id,
            type: conversationDetail.type ,
            recipient_other_id: recipient_other_id,
          };
          axios
            .get("/templates", {
              params: data,
            })
            .then((resp) => {
              this.templates = resp.data.data;
            });

          this.templateModal = !this.templateModal;
      },
     subscribe() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, { cluster: process.env.VUE_APP_PUSHER_CLUSTER });
      pusher.subscribe("chat");
      pusher.bind(this.$store.getters.getconversationOpeningDetailsData.prescriber_id.toLowerCase(), (data) => {
        this.chats.push(data);
      });
    },
    sendPreApprovedTemplates(){
      let conversationDetail = this.$store.getters.getconversationOpeningDetailsData;
      let active_campaign_ID = this.selectedCampaignID;     
      let recipient_id       = conversationDetail.recipient_id;
      let phone              = conversationDetail.phone_number;
      let hcp_id             = conversationDetail.prescriber_id;
      let recipient_name =  conversationDetail.recipient_name
   
      let data = {
        recipient_name : recipient_name,
        recipient_id   : recipient_id,
        physcian_id    : hcp_id,
        type           : conversationDetail.type,
        template_id    : this.selectedTemplateData.Template_ID,
        assets         : this.selectedTemplateData.Asset,
        template       : this.selectedTemplateData.Template_body,
        recipient_phone: phone,
        Campaign_ID    : active_campaign_ID,
      };
      console.log(data)

      axios.post("/send-sms", data)
      .then((resp) => {
        console.log(resp)
        if (resp.data.success) {
          this.closeTemplateSelected();
          alert(resp.data.message);
          this.$refs["preApprovedTemplateModal"].hide();
          this.getData();
        }
      })
      .catch(err => {
       console.log(err);
      })
    },
     sendCustomMessage() {
      let conversationDetail = this.$store.getters.getconversationOpeningDetailsData;
      console.log(conversationDetail)
      var recipient_id = "";
      var recipient_name = "";
      var physcian_id = conversationDetail.prescriber_id;
       console.log(physcian_id);
        console.log(recipient_name);
      
      if (conversationDetail.type == 1) {
        recipient_id   = conversationDetail.recipient_id;
        recipient_name = conversationDetail.recipient_name;
      }
      let data = {
        physcian_id: physcian_id,
        recipient_name: recipient_name,
        recipient_phone: conversationDetail.phone_number,
        recipient_id: recipient_id,
        template_id: "",
        type: conversationDetail.type == 1 ? true : false,
        template: this.form.template_data,
        Campaign_ID: this.$store.getters.getActiveCampaignId,
      };
      console.log(data);
      axios
        .post("send-custom-sms", data)
        .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              if(resp.data.message === 'Inappropriate message body'){
                this.sensitiveMsg = true;
              }else{
                this.sensitiveMsg = false;
                alert(resp.data.message);
                this.form.template_data = "";
                // this.getData();
                this.chats = this.filterconverstions;
              }
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkSensitive(){
      this.sensitiveMsg = false;
    },
    getTemplates() {
      let conversationDetail = this.$store.getters.getconversationOpeningDetailsData;
      console.log(conversationDetail);
      let active_campaign_ID = this.$store.getters.getActiveCampaignId;
   
      let phone = conversationDetail.phone_number;
      let hcp_id =  conversationDetail.prescriber_id;
      let recipient_other_id  = conversationDetail.recipient_id;
     
      let data = {
        campaign_id: active_campaign_ID,
        phone: phone,
        hcp_id: hcp_id,
        type: conversationDetail.type ,
        recipient_other_id: recipient_other_id,
      };
      axios
        .get("/templates", {
          params: data,
        })
        .then((resp) => {
          this.templates = resp.data.data;
        });

      this.templateModal = !this.templateModal;
    },

    scrollToBottom() {
        // var box = document.body;
        // var height = box.clientHeight;
        // window.scrollTo(0, height);
    },
    showModal(){
        this.$refs['preApprovedTemplateModal'].show()
        this.getTemplates();
    },
   
    getData(pageNo) {
      let recepient = this.$store.getters.getconversationOpeningDetailsData.prescriber_id;
      let recepient_name = this.$store.getters.getconversationOpeningDetailsData.HCP_name;
      if(this.$store.getters.getconversationOpeningDetailsData.type == 1){
        recepient_name += '-'+this.$store.getters.getconversationOpeningDetailsData.recipient_name;
      }
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/converstations/" + recepient, {
          params: {
            page: pageNo,
            limit: 10,
            recepient_name: recepient_name,
          },
        })
        .then((resp) => {
          console.log(resp)
          this.chats = resp.data.data.reverse();
          this.loading = false;
          for (var index in resp.data.data) {
            if (resp.data.data[index].ReadAt == "") {
              this.updateReadAtDate();
              break;
            }
          }
          resp.data.data.map((data) => {
            if (data.RecepientID == recepient) {
              this.recepient_name = data.recepient_name;
            }
          });
        })
        .catch((error) => {
          // Rollbar Begin
          this.$rollbar.error("Chat data failed to fetch with the " + error);
          // Rollbar End
        });
    },
    infiniteHandler($state) {
      this.state = $state;
      let recepient = this.$store.getters.getconversationOpeningDetailsData.prescriber_id;
      let recepient_name =  this.$store.getters.getconversationOpeningDetailsData.HCP_name;

      if(this.$store.getters.getconversationOpeningDetailsData.type == 1){
        recepient_name += '-'+this.$store.getters.getconversationOpeningDetailsData.recipient_name;
      }


      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get("/converstations/" + recepient, {
          params: {
            page: this.page,
            limit: this.limit,
            recepient_name: recepient_name,
          },
        })
        .then(({ data }) => {
          if (data.data.length) {
            if (data.data.length === this.limit) {
              this.page += 1;
            } else {
              $state.complete();
            }
            this.chats.unshift(...data.data.reverse());
            this.filterconverstions = this.chats;
            this.loading = false;
            this.scrollToBottom();
            for (var index in data.data) {
              if (data.data[index].ReadAt == "") {
                this.updateReadAtDate();
                break;
              }
            }
            data.data.map((data) => {
              if (data.RecepientID == recepient) {
                this.recepient_name = data.recepient_name;
              }
            });
            data.data.map((data) => {
              if (
                data.attach1_type === ".jpg" ||
                data.attach1_type === ".gif" ||
                data.attach1_type === ".png"
              ) {
                this.onImgLoad();
              } else {
                this.scrollToBottom();
              }
            });
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    onImgLoad() {
      this.scrollToBottom();
    },
    updateReadAtDate() {
      let recepient = this.$store.getters.getconversationOpeningDetailsData.prescriber_id;
      var token = localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios.put("/read-at/" + recepient).then((resp) => {
        console.log(resp.data.data);
      });
    },
    toggleFunc(el) {
      // var el = this;
      // var target = el.getAttribute('data-target');
      console.log(el);
    },
    selectedTemplate(reference, index) {
      var list = document.querySelectorAll("li"),
        i = list.length;

      while (i--) {
        list[i].classList.remove("selected");
      }
      console.log(reference);
      this.selectedTemplateData = this.templates[index];
      document.getElementById(reference).classList.add("selected");
    },
    closeTemplateSelected(){
      this.templateModal =  this.selectedTemplateData = false ;
       var list = document.querySelectorAll("li"),
        i = list.length;

      while (i--) {
        list[i].classList.remove("selected");
      }
    },
  },
};
</script>
<style scoped>
  body{
    min-height: 100vh;
  }
</style>
