<template>
  <!-- Content Begin -->
  <main class="theme-main theme-main-full">
    <div class="theme-main-body">
      <div class="p-2">
        <button type="button" onclick="window.history.back()" class="btn-light btn-icon mb-3">
            <feather type="chevron-left" size="18"></feather>
        </button>
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="bg-white br-15 p-3">
                    <div class="avatar avatar-xl avatar-rounded text-center mb-1">
                        <img src="@/assets/img/user.png" alt="" />
                    </div>
                    <h4 class="text-info bold mb-1">{{ this.$store.getters.getLoggedInUserName}}</h4>
                    <p class="text-gray-400 bold mb-0">{{ wformatPhoneNumber(getTenDigitPhoneNumber(this.$store.getters.getLoggedInVirtualNumber)) }}</p>
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Content End -->
</template>

<script>
export default {
  components: {
      
  },
  data() {
    return {
        
    };
  },
  methods: {
      
  },
};
</script>

<style lang="scss" scoped>
.theme {
  &-main-full {
    .theme-main-body {
      height: 100vh;
    }
  }
}
</style>