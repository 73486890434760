const config = {
    authority: process.env.VUE_APP_OKTA_AUTHORITY,
    clientId: process.env.VUE_APP_OKTA_CLIENTID,
    clientSecret: process.env.VUE_APP_OKTA_CLIENTSECRET,
    redirectUri: window.location.origin +"/cordastin/implicit/callback",
    responseType: "code",
    scope: "openid profile email",
    type: 'okta',
    metadata: {
      issuer: process.env.VUE_APP_OKTA_ISSUER,
      authorization_endpoint: process.env.VUE_APP_OKTA_AUTHORIZATION_ENDPOINT,
      token_endpoint: process.env.VUE_APP_OKTA_TOKEN_ENDPOINT,
      userinfo_endpoint: process.env.VUE_APP_OKTA_USERINFO_ENDPOINT
    }
  }
  
  
  export default config
  