import Vue from "vue";
var tokenMixin = Vue.mixin({
  methods: {
    wformatPhoneNumber(str) {
      //Filter only numbers from the input
      let cleaned = ("" + str).replace(/\D/g, "");
      console.log(cleaned)
      console.log("cleaned")

      //Check if the input is of correct
      let match = cleaned.match(/^(1|91|)?(\d{3})(\d{3})(\d{4})$/);
    
      if (match) {
        console.log(match);
        console.log("match")
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = match[1] ? "+1 " : "+1";
        return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
      }else{
        return null;
      }

      
    },

    getTenDigitPhoneNumber(phone) {
      if (phone.length == 11) return phone.substring(1, 11);
      else if (phone.length == 12) return phone.substring(2, 12);
      else if (phone.length == 10) return phone;
      else return phone
    },
  },
});

export default tokenMixin;
