import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import axios from "axios";

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    access_token: "",
    name: "",
    id: "",
    virtual_number: "",
    campaigns: "",
    active_campaign_ID: "",
    active_campaign: "",
    refresh_token: "",
    conversationDetails: "",
    conversationOpeningDetailsData: {},
    contactDetails:''
  },
  getters: {
    getConversationDetails: (state) => {
      return state.conversationDetails;
    },
    getcontactDetails: (state) => {
      return state.contactDetails;
    },
    getAccessToken: (state) => {
      return state.access_token;
    },
    getRefreshToken: (state) => {
      return state.refresh_token;
    },
    getLoggedInUserName: (state) => {
      return state.name;
    },
    getLoggedInVirtualNumber: (state) => {
      return state.virtual_number;
    },
    getAllCampaigns: (state) => {
      return JSON.parse(state.campaigns);
    },
    getActiveCampaignName: (state) => {
      return state.active_campaign;
    },
    getActiveCampaignId: (state) => {
      return state.active_campaign_ID;
    },
    getconversationOpeningDetailsData: (state) => {
      return state.conversationOpeningDetailsData;
    },
    getLoggedInID: (state) => {
      return state.id;
    },
  },
  mutations: {
    conversationOpeningDetails(state, payload) {
      //HCP_name
      //phone_number
      //prescriber_id
      //type
      state.conversationOpeningDetailsData.HCP_name = payload.HCP_name;
      state.conversationOpeningDetailsData.phone_number = payload.phone_number;
      state.conversationOpeningDetailsData.prescriber_id =
        payload.prescriber_id;
      state.conversationOpeningDetailsData.type = payload.type;
      state.conversationOpeningDetailsData.recipient_name =
        payload.recipient_name;
      state.conversationOpeningDetailsData.recipient_id = payload.recipient_id;
      console.log(state.conversationOpeningDetailsData);
      // store in the local storage

      console.log(state.conversationOpeningDetailsData);
      localStorage.setItem(
        "conversationOpeningDetailsData_HCP_name",
        payload.HCP_name
      );
      localStorage.setItem(
        "conversationOpeningDetailsData_phone_number",
        payload.phone_number
      );
      localStorage.setItem(
        "conversationOpeningDetailsData_prescriber_id",
        payload.prescriber_id
      );
      localStorage.setItem("conversationOpeningDetailsData_type", payload.type);
      localStorage.setItem(
        "conversationOpeningDetailsData_recipient_id",
        payload.recipient_id
      );
      localStorage.setItem(
        "conversationOpeningDetailsData_recipient_name",
        payload.recipient_name
      );
    },
    initialize(state) {
      if (localStorage.getItem("conversationOpeningDetailsData_HCP_name")) {
        state.conversationOpeningDetailsData.HCP_name = localStorage.getItem(
          "conversationOpeningDetailsData_HCP_name"
        );
      }
      if (localStorage.getItem("conversationOpeningDetailsData_phone_number")) {
        state.conversationOpeningDetailsData.phone_number = localStorage.getItem(
          "conversationOpeningDetailsData_phone_number"
        );
      }
      if (
        localStorage.getItem("conversationOpeningDetailsData_prescriber_id")
      ) {
        state.conversationOpeningDetailsData.prescriber_id = localStorage.getItem(
          "conversationOpeningDetailsData_prescriber_id"
        );
      }
      if (localStorage.getItem("conversationOpeningDetailsData_type")) {
        state.conversationOpeningDetailsData.type = localStorage.getItem(
          "conversationOpeningDetailsData_type"
        );
      }
      if (localStorage.getItem("conversationOpeningDetailsData_HCP_name")) {
        state.conversationOpeningDetailsData.HCP_name = localStorage.getItem(
          "conversationOpeningDetailsData_HCP_name"
        );
      }
      if (localStorage.getItem("conversationOpeningDetailsData_recipient_id")) {
        state.conversationOpeningDetailsData.recipient_id = localStorage.getItem(
          "conversationOpeningDetailsData_recipient_id"
        );
      }
      if (
        localStorage.getItem("conversationOpeningDetailsData_recipient_name")
      ) {
        state.conversationOpeningDetailsData.recipient_name = localStorage.getItem(
          "conversationOpeningDetailsData_recipient_name"
        );
      }

      if (localStorage.getItem("access_token")) {
        state.access_token = localStorage.getItem("access_token");
      }
      if (localStorage.getItem("name")) {
        state.name = localStorage.getItem("name");
      }
      if (localStorage.getItem("id")) {
        state.id = localStorage.getItem("id");
      }
      if (localStorage.getItem("virtual_number")) {
        state.virtual_number = localStorage.getItem("virtual_number");
      }
      if (localStorage.getItem("campaigns")) {
        state.campaigns = localStorage.getItem("campaigns");
      }
      if (localStorage.getItem("active_campaign_ID")) {
        state.active_campaign_ID = localStorage.getItem("active_campaign_ID");
      }
      if (localStorage.getItem("active_campaign")) {
        state.active_campaign = localStorage.getItem("active_campaign");
      }
      if (localStorage.getItem("refresh_token")) {
        state.refresh_token = localStorage.getItem("refresh_token");
      }
      if (localStorage.getItem("conversationDetails")) {
        state.conversationDetails = JSON.parse(
          localStorage.getItem("conversationDetails")
        );
      }
      if (localStorage.getItem("contactDetails")) {
        state.contactDetails = JSON.parse(
          localStorage.getItem("contactDetails")
        );
      }
    },
    setConversationDetails(state, data) {
      localStorage.setItem("conversationDetails", JSON.stringify(data));
      state.conversationDetails = data;
    },
    setContactDetails(state, data) {
      localStorage.setItem("contactDetails", JSON.stringify(data));
      state.contactDetails = data;
    },
    setLoggedInUserInfo(state, data) {
      state.access_token = data.token;
      state.name = data.name;
      state.id = data.id;
      state.virtual_number = data.virtual_number;
      state.campaigns = data.campaigns;
      state.refresh_token = data.refresh_token;
    },
    setActiveCampignInfo(state, data) {
      console.log(data);
      state.active_campaign = data.active_campaign;
      state.active_campaign_ID = data.active_campaign_ID;
    },
  },
  actions: {
    login({ commit }) {
      let oktaToken = localStorage.getItem("okta-token-storage");
      let oktaTokenJSON = JSON.parse(oktaToken);
      let token = oktaTokenJSON.access_token;
      var header = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"] = header;
        axios
          .post("/auth/okta", {
            type: localStorage.getItem("sso_type")
          })
          .then((res) => {
            if (res.data.success) {
              commit("setLoggedInUserInfo", res.data.data);
              resolve(res.data.data);
            }
            resolve(res.data.data);
          })
          .catch(err => {
            console.log(err.response.status)
            reject(err)
          });
      });
    },
  },
});
