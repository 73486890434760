<template>
  <div class="sidebar-default">
    <div class="px-2 mb-2">
        <HistorySubNav></HistorySubNav>
    </div>
    <div class="sidebar-body"  @click="closeMenuFunc" >
      <ChatTabView></ChatTabView> 
    </div>
  </div>
</template>

<script>
import ChatTabView    from "@/components/Chat/ChatTabView.vue";
import HistorySubNav from '@/components/navbar/HistorySubNav'

export default {
  data() {
    return {
      toggleMenu: false,
      settings: {
        maxScrollbarLength: 60,
      },
      tabs: [
        {
          id: 0,
          icon: "home",
          title: "Home ",
        },
        {
          id: 1,
          icon: "users",
          title: "Contacts",
        },
        {
          id: 2,
          icon: "phone",
          title: "Chats",
        },
      ],
      chatTab: 0
     
    };
  },
  created() {
      this.$store.commit('initialize');
  },
  methods: {
    toggleMenuFunc(){
      this.toggleMenu = !this.toggleMenu;
      this.$emit('clicked', this.toggleMenu);
    },
    closeMenuFunc(){
      if(this.toggleMenu){
        this.toggleMenu = !this.toggleMenu;
        this.$emit('clicked', this.toggleMenu);
      }
    },
    autoAdjustHeight(){
      const header = document.getElementsByClassName('sidebar-main')[0].clientHeight;
      const sidebarDefault = document.getElementsByClassName('sidebar-default')[0];
      const subMenu = sidebarDefault.getElementsByClassName('px-2 mb-2')[0].clientHeight;
      const search = document.getElementsByClassName('search-box')[0].clientHeight;
      const footer = document.getElementsByClassName('sidebar-footer')[0].clientHeight;
      const screenHeight = window.innerHeight;
      document.getElementsByClassName('chat-main')[0].style.height = (screenHeight - (header + subMenu + search + footer + 20)) + 'px';
    }
  },
  mounted() {
    this.autoAdjustHeight();
  },
  updated() {
    this.autoAdjustHeight();
  },
  components: {
    HistorySubNav,
    ChatTabView,
  },
};
</script>

<style lang="scss">
  .overflowHidden{
    overflow: hidden !important;
  }
</style>
