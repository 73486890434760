import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import mixin from "./mixins/utils";

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { required, } from 'vee-validate/dist/rules';
extend('required', required);
extend('secret', {
  validate(inpvalue) {
    var restrictedcharacters = /([<>"=/])+/g;
    if (inpvalue.match(restrictedcharacters)) {
      return false;
    }
    else
      return true;
  }
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Auth
import Auth from "./mixins/Auth";
Vue.mixin(Auth);

// autocomplete
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

Vue.use(Autocomplete);

// Global CSS
import "./assets/sass/main.scss";

// Bootstrap
import { BootstrapVue } from "bootstrap-vue";
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

// BlockUI
import BlockUI from "vue-blockui";
Vue.use(BlockUI);

// InfiniteLoading
import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading);

// VueFeather
import VueFeather from "vue-feather";
Vue.use(VueFeather);

// moment
Vue.use(require("vue-moment"));

// Axios
import axios from "axios";
// add these before Vue is instantiated
window.axios = axios;
//axios.defaults.baseURL = 'https://gartnermsgappapi-dev.azurewebsites.net/api'
//axios.defaults.baseURL = "https://abbviemsgappapi-dev.azurewebsites.net/api";
//axios.defaults.baseURL = 'https://abbviemsgappapi-qa.azurewebsites.net/api'
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
console.log(process.env.VUE_APP_API_ENDPOINT)

axios.interceptors.response.use((response) => {
  console.log(response)
  return response;
}, function (error) {
  console.log("interceptor error")

    if (error.response.data.data === 'No user found') 
    {
      let prompt = confirm(error.response.data.data);
      if (prompt) {
        localStorage.clear();
        router.push("/");
      } else {
        localStorage.clear();
        router.push("/");
      }
    }else{
      localStorage.clear();
      router.push("/");
    }
  return Promise.reject(error);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  mixin,
  render: (h) => h(App),
}).$mount("#app");
