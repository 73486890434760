<template>
  <div class="px-2 pb-5 scrollbox">
    <ValidationObserver v-slot="{ invalid }">
    <form @submit.prevent="submitSMSData">
      <div class="form-group">
        <p class="text-primary font-weight-bold">Select Type</p>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="physician"
            name="type"
            v-model.number="form.type"
            @change="changeType()"
            value="0"
            class="custom-control-input"
          />
          <label for="physician" class="custom-control-label">Prescriber</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="others"
            name="type"
            v-model.number="form.type"
            @change="changeType()"
            value="1"
            class="custom-control-input"
          />
          <label for="others" class="custom-control-label">Others</label>
        </div>
      </div>
      <div class="form-group">
        <p class="text-primary font-weight-bold" v-if="form.type == 0">
          Prescriber
        </p>
        <p class="text-primary font-weight-bold" v-else>
          Associated Prescriber
        </p>

        <autocomplete
          ref="prescriber"
          @submit="handleSubmit"
          :search="search"
          :debounceTime="500"
          :get-result-value="getResultValue"
          :placeholder="
            form.type == 0
              ? 'Search for a Prescriber'
              : 'Search for an Associated Prescriber'
          "
        >
        <template #result="{ result, props }">
          <li
            v-bind="props"
            class="autocomplete-result wiki-result"
          >
            <div class="wiki-title">
              {{ result.account_name }}
            </div>
            
          </li>
        </template>
        </autocomplete>
      </div>
      <div
        class="form-group floating-form form-autocomplete"
        :class="{
          'form-autocomplete-shown':
            filterOthers.length > 0 && autocompleteIsActive,
        }"
        v-if="form.type == 1"
      >
        <p class="text-primary font-weight-bold">Recipient</p>
        <input
          type="text"
          placeholder="Enter Recipient name and Title ('Kelly Smith- MA')"
          class="form-control"
          ref="rec_name"
          :disabled="perscriberInactive"
          v-model="form.recipient.name"
          @change="onChangeOthers()"
          @blur="closeAutocomplete()"
          @keyup="checkOthers($event)"
          autocomplete="off"
        />
        <ul
          class="form-autocomplete-list"
          v-if="filterOthers.length > 0 && autocompleteIsActive"
        >
          <li
            v-for="(other, index) in filterOthers"
            :key="index"
            @click="setOthersValue(other)"
          >
            {{ other.name }}
          </li>
        </ul>
      </div>
      <div class="form-group floating-form">
        <input
          type="text"
          id="phone_no"
          inputmode="url"
          name="phone_no"
          placeholder="Please enter the phone number"
          class="form-control"
          v-model="form.phone_number"
          ref="phone_number"
          :readonly="perscriberInactive"
          @change="checkTemplates"
          @keyup="checkTemplates"
          autocomplete="off"
        />
        <label for="">Phone Number</label>
        <p
          class="label-error"
          v-if="phoneNumberError"
          v-show="form.phone_number != ''"
        >
          Enter a valid phone number
        </p>
      </div>
      <!-- <div class="form-group floating-form">
       <select
          class="form-control"
          v-model="selectedCampaignID"
          @change="GetTheTemplateBasedOnTheProduct"
        >
          <option
            :value="campaigns[val].CampaignID"
            v-for="(index, val) in campaigns"
            :key="index"
          >
            {{ campaigns[val].product_name }}
          </option>
        </select>
     </div> -->

      <div class="form-group floating-form">
        <p class="text-primary font-weight-bold">SMS Template</p>
        <select
          name=""
          id=""
          class="form-control"
          @change="changedTemplate()"
          :disabled="templates.length === 0"
          v-model="form.template_id"
        >
          <option value="">Select Template</option>

          <option
            :value="template.Template_ID"
            v-for="template in templates"
            :key="template.Template_ID"
          >
            {{ template.Template_Name }}
          </option>
        </select>
        <label for="">SMS Template</label>
      </div>
      <div class="form-group" v-if="form.template_id">
        <div class="floating-form">
          <ValidationProvider v-slot="v" rules="secret|required">
            <textarea
            name="template body"
            :disabled="CustomTemplate"
            class="form-control"
            ref="logicalText"
            maxlength="1500"
            @keyup="updateElSize($event)"
            style="max-height: 30vh;"
            cols="30"
            rows="5"
            v-model="form.template"
          ></textarea>
            <span class="error">{{ v.errors[0] }}</span>
          </ValidationProvider>
          
          <label for="">SMS Template</label>
        </div>
        <p class="font-sm text-right">
          <em>Characters : {{ form.template.length }}</em>
          <!-- <em v-else>Characters Limit : {{ 160 - form.template.length }}</em> -->
        </p>
      </div>
      <!-- <div class="form-group floating-form">
        <select
          name=""
          id=""
          class="form-control"
          disabled
          @change="assetChanged"
          v-model="form.asset"
        >
          <option value="">Select Asset</option>
          <option
            :value="asset"
            v-for="asset in assets"
            :key="asset.asset_id">
            {{ asset.Asset_Name }}
          </option>
        </select>
        <label for="">Assets</label>
      </div> -->

      <div class="form-group form-list" v-if="showAssets">
        <label for="">Assets</label>
        <ul class="list list-styled list-styled-gray-200">
          <li v-for="asset in assets" :key="asset.asset_id">
            <a target="_blank" :href="asset.file_url">{{ asset.Asset_Name }}</a>
          </li>
        </ul>
      </div>
      <!-- <div class="form-group" v-if="form.asset">
        <p>
          Click
          <a
            :href="form.asset.file_url"
            class="text-primary font-weight-bold"
            target="_blank">here</a>
            to view the asset
        </p>
      </div> -->
      <div class="row" v-if="sendSms">
        <div class="col-8 offset-2">
          <button
            type="submit"
            :disabled="processing || invalid"
            class="btn btn-primary btn-block btn-rounded"
          >
            Send SMS
          </button>
        </div>
      </div>
    </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider} from 'vee-validate';
import axios from "axios";
// import loadingImage from '@/public/preloader.gif'
const count = 1;

export default {
  components: {ValidationProvider},
  data: function () {
    return {
      selectedCampaignID: "",
      campaigns: "",
      showNav: false,
      count: count,
      isDrawer: false,
      claims: "",
      CustomTemplate: true,
      form: {
        phone_number: "",
        physician_id: "",
        prescriber_id: "",
        asset: "",
        template_id: "",
        template: "",
        recipient: {
          id: "",
          name: "",
        },
        type: 0,
      },
      oldPhoneNumber: false,
      phoneNumberError: true,
      response: false,
      templates: "",
      assets: "",
      showAssets: false,
      prescribers: "",
      device_info: "",
      loggedInName: "",
      processing: false,
      msg: "Please wait for the application to retrieve the token from IDP.",
      url: `<img src="/preloader.gif" width="90"/>`,
      loading: false,
      campaignisActive: false,
      campaignName: "",
      others: [],
      templateSelectionInactive: true,
      filterOthers: [],
      perscriberInactive: true,
      autocompleteIsActive: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendSms() {
      let valid = "";
      if (this.form.type === 0) {
        valid =
          this.form.template_id &&
          this.form.physician_id &&
          this.form.phone_number &&
          this.$refs.prescriber.value;
      } else {
        valid =
          this.form.template_id &&
          this.form.physician_id &&
          this.form.phone_number &&
          this.form.recipient &&
          this.$refs.prescriber.value;
      }
      if (valid && this.phoneNumberValidation()) {
        return true;
      }
    },
  },
  created() {
    this.$store.commit("initialize");
  },
  mounted() {
    this.getCampaignDetails();
  },
  methods: {
    logicalTextCondition(){
      setTimeout(() => {
        var maxlength = 160;
        var el = this.$refs.logicalText;
        el.maxLength = maxlength;
        el.focus();
      }, 1000);
    },
    getCampaignDetails() {
      this.campaigns = this.$store.getters.getAllCampaigns;
      this.selectedCampaignID = this.$store.getters.getActiveCampaignId;
    },
    passToNav() {
      this.showNav = !this.showNav;
    },
    closeAutocomplete() {
      setTimeout(() => {
        this.autocompleteIsActive = false;
      }, 1000);
    },
    checkOthers(event) {
      this.form.recipient.id = "";
      var value = event.target.value;
      if (this.others.length > 0 && value) {
        this.searchothers(value);
        this.autocompleteIsActive = true;
      } else {
        this.filterOthers = [];
        this.autocompleteIsActive = false;
      }
    },
    onChangeOthers() {
      if (!this.form.recipient.id) {
        this.form.phone_number = "";
      }
    },
    setOthersValue(result) {
      this.filterOthers = [];
      this.form.phone_number = result.phone;
      this.form.recipient.name = result.name;
      this.form.recipient.id = result.id;
      if (result.phone) {
        this.checkTemplates();
      }
    },
    searchothers(value) {
      this.filterOthers = this.others.filter((other) => {
        return value
          .toLowerCase()
          .split(" ")
          .every((v) => other.name.toLowerCase().includes(v));
      });
    },
    setAlertMessages(condition) {
      if (condition) {
        this.msg =
          "Please wait for the application to retrieve the token from oAuth provider.";
      } else {
        this.msg =
          "Sorry, there was some issue while retrieving the token from oAuth provider.";
      }
    },
    async logout() {
      try {
        await this.$auth.logout();
        localStorage.clear();
      } catch (err) {
        console.log(err);
      }
    },

    changeType() {
      this.form.phone_number = this.form.template_id = this.form.template = this.form.physician_id = this.form.asset = this.form.recipient.name = this.form.recipient.id = this.oldPhoneNumber = this.$refs.prescriber.value =
        "";

      // Reset fields
      this.perscriberInactive = true;
      this.showAssets = false;
      this.templates = this.assets = [];
    },
    async search(input) {
      if (input.length < 2) {
        return [];
      }
      let token = this.$store.state.access_token;
      axios.defaults.headers.common["Authorization"] = token;
      let prescribers = await axios.get("/prescribers", {
        params: {
          name: input,
        },
      });
      return prescribers.data.data.filter((prescriber) => {
        return (
          prescriber.HCP_name.toLowerCase().startsWith(input.toLowerCase())||
           prescriber.first_name.toLowerCase().startsWith(input.toLowerCase()) || 
           prescriber.last_name.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    async handleSubmit(result) {
      this.oldPhoneNumber = "";
      this.form.physician_id = result;
      this.form.prescriber_id = result.prescriber_id;
      this.perscriberInactive = false;

      let token = this.$store.getters.getAccessToken;
      axios.defaults.headers.common["Authorization"] = token;
      if (this.form.type === 0) {
        this.form.phone_number = result.Phone_Number;
        console.log(result.Phone_Number);
        if (result.Phone_Number) this.checkTemplates();
        else this.form.phone_number = "";
        // this.$refs.phone_number.focus();
      } else {
        let othersRes = await axios.get("/other-contact-lists", {
          params: {
            prescriber_id: result.prescriber_id,
          },
        });
        this.others = othersRes.data.data;
      }
    },
    getResultValue(result) {
      return result.HCP_name;
    },

    getDeviceInfo() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /Windows Phone/i,
        /BlackBerry/i,
        /Macintosh/i,
      ];
      let device;
      toMatch.some((toMatchItem) => {
        if (navigator.userAgent.match(toMatchItem)) {
          device = toMatchItem;
          return toMatchItem;
        } else return false;
      });
      return device;
    },
    submitSMSData() {
      this.processing = true;
      var active_campaign_ID = this.$store.getters.getActiveCampaignId;
      let data = {
        recipient_name: this.form.recipient.name,
        recipient_id: this.form.recipient.id,
        physcian_id: this.form.physician_id.prescriber_id,
        type: this.form.type,
        template_id: this.form.template_id,
        assets: this.form.asset,
        template: this.form.template,
        recipient_phone: this.form.phone_number,
        Campaign_ID: active_campaign_ID,
      };

      axios
        .post("/send-sms", data)
        .then((resp) => {
          if (resp.data.success) {
            alert(resp.data.message);
            this.processing = false;
            this.changeType();
            this.form.type = 0;
          }
        })
        .catch((err) => {
          alert(err.response.data);
          this.changeType();
        });
    },

    submitForm() {
      this.processing = true;
      let data = {
        assetId: this.form.asset.asset_id,
        recipient_name: this.form.recipient.name,
        recipient_id: this.form.recipient.id,
        physcian_id: this.form.physician_id.prescriber_id,
        type: this.form.type,
        template_id: this.form.template_id,
        assets: this.form.asset,
        recipient_phone: this.form.phone_number,
      };

      let currentDevice = this.getDeviceInfo();
      let phone_number =
        this.form.type == 0 ? this.form.phone_number : this.form.phone_number;

      axios
        .post("/logs", data)
        .then((resp) => {
          if (resp.data.success) {
            this.form.asset_url = resp.data.url;
            if (
              currentDevice == "/Macintosh/i" ||
              currentDevice == "/iPhone/i" ||
              currentDevice == "/iPad/i"
            )
              window.location.href =
                "sms:" +
                phone_number +
                "&body=" +
                this.checkStringVariableExist(this.form.asset_url);
            else
              window.location.href =
                "sms:" +
                phone_number +
                "?body=" +
                this.checkStringVariableExist(this.form.asset_url);
          }
          this.processing = false;
          this.changeType();
          this.form.type = 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    checkStringVariableExist() {
      //var t = '';
      var str = this.form.template;
      return str;
      // var str_lower = str.toLowerCase();
      // var word_find = "[shorturl]";
      // var word_lower = word_find.toLowerCase();
      // var str_pos = str_lower.indexOf(word_lower);

      // if (str_pos > -1) {
      //   t = str.replace(word_find, url);
      //   return t
      // }else{
      //   t = str+url;
      //   return t
      // }
    },
    phoneNumberValidation() {
      let phone = this.form.phone_number;
      phone = phone.replace(/\W/g, "");
      this.form.phone_number = phone;
      if (!Number(phone)) {
        this.phoneNumberError = true;
        return false;
      }
      if (phone.length < 10) {
        this.phoneNumberError = true;
        return false;
      } else if (phone.length == 10) {
        this.phoneNumberError = false;
        return true;
      } else if (phone.length == 11) {
        var res = phone.charAt(0);
        if (res != 1) {
          this.phoneNumberError = true;
          return false;
        } else {
          this.phoneNumberError = false;
          return true;
        }
      } else {
        this.phoneNumberError = true;
        return false;
      }
    },
    changedTemplate() {
      let vm = this;
      vm.CustomTemplate = true;

      for (var i = 0; i < this.templates.length; i++) {
        if (this.templates[i].Template_ID == vm.form.template_id) {
          vm.form.template = this.templates[i].Template_body;
          this.showAssets = this.templates[i].show_assets;
          if (this.templates[i].custom_body) {
            vm.CustomTemplate = false;
            // this.logicalTextCondition();
          }
          if (this.templates[i].Asset) {
            this.assets = JSON.parse(this.templates[i].Asset);
            this.form.asset = this.assets;
          } else {
            this.form.asset = "";
            this.assets = "";
            this.showAssets = false;
          }
          break;
        } else {
          this.form.asset = "";
          this.assets = "";
          this.showAssets = false;
        }
      }
    },
    assetChanged() {},
    async getTemplates() {
      var vm = this;

      if (this.oldPhoneNumber != this.form.phone_number) {
        this.templates = [];
        let token = this.$store.getters.getAccessToken;
        let active_campaign_ID = this.$store.getters.getActiveCampaignId;
        axios.defaults.headers.common["Authorization"] = token;
        let phoneNumber = this.form.phone_number;

        axios
          .get("/templates", {
            params: {
              campaign_id: active_campaign_ID,
              phone: phoneNumber,
              hcp_id: vm.form.prescriber_id,
              type: vm.form.type,
              recipient_other_id: vm.form.recipient.id,
            },
          })
          .then((res) => {
            this.oldPhoneNumber = phoneNumber; // set old phone number
            if (res.data.success) {
              this.templateSelectionInactive = false;
              this.templates = res.data.data;
            } else {
              this.templateSelectionInactive = false;
              this.templates = [];
              this.form.template_id = "";
              alert(res.data.message);
            }
          });
      }
    },
    updateElSize(e) {
      var el = e.target;
      el.style.height = "auto";
      el.style.height = el.scrollHeight + 'px';
    },
    checkTemplates() {
      if (
        this.form.phone_number != this.oldPhoneNumber ||
        this.form.phone_number == ""
      ) {
        this.form.template = this.oldPhoneNumber = this.form.template_id = "";
        this.templates = this.assets = [];
      }
      console.log(this.form.phone_number);
      this.phoneNumberValidation();
      if (!this.phoneNumberError) {
        this.getTemplates();
      }
    },
    async getHcp() {},
  },
};
</script>

<style>
/* Block UI */
.loading-container .loading {
  padding: 20px 30px !important;
  width: 90%;
  max-width: 500px;
}
.loading-container .loading-label {
  font-size: 15px;
  color: #6c757d;
}
.autocomplete-others .autocomplete > input.autocomplete-input {
  padding-left: 1rem;
  background-image: none;
}
.error{
  color: red;

}
</style>
