<template>
  <div class="chat-box">
    <!-- If Image -->
    
    <!-- <div @click="contactSelected(dataObj)"> -->
    <div>
       <button href="#" class="sidebar-main-icon btn-icon" >
        
          <feather type="phone-incoming" size="12" v-if="dataObj.direction == 'inbound'" ></feather>
          <feather type="phone-outgoing" size="12" v-if="dataObj.direction == 'outbound-dial'"></feather>
      </button>
    </div>
    <div class="chat-box-details">
      <h5 v-if="dataObj.recepientRole_ID == 1" :class="{ 'text-danger' : (dataObj.direction == 'inbound-dial' && dataObj.callstatus == 'no-answer')  }">{{ dataObj.prescribername }}</h5>
      <h5 v-else :class="{ 'text-danger' : (dataObj.direction == 'inbound-dial' && dataObj.callstatus == 'no-answer')  }">{{ dataObj.othersname }}</h5>
      <h6 v-if="dataObj.recepientRole_ID == 1">HCP</h6>
      <h6 v-else>Office Staff</h6>
    </div>
    <div class="chat-box-date-status">
    {{ dataObj.start_time | moment("MM/DD/YYYY hh:mm") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
   dataObj:{
     type: Object
   }
  },
  methods: {
    contactSelected(contact) {
      let url = "/log/" + contact.prescriber_id + "?user=" + contact.user_name;
      this.$router.push(url);
    },
    initiateCall(dataObj){
       this.$emit('initiateCall',dataObj);
    }
  },
};
</script>
<style scoped>

</style>