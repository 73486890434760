<template>
    <main class="login-screen">
        <a href="#" class="login-screen-logo">
            <img src="@/assets/img/logo.png" alt="">
        </a>
        <div class="login-screen-body">
            <h1 class="bolder font-xxxl text-gray-400">{{ type }}</h1>
            <h2 class="bold mb-2 text-gray-400">{{ title }}</h2>
            <p class="mb-5">{{ msg }}</p>
            <router-link class="btn btn-info" to="/">Go back to Login</router-link>
        </div>
    </main>
</template>
<script>
export default {
    data(){
        return{
            error: [
                { type: '404', title: 'Page Not Found', msg: 'The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.' },
                { type: '401', title: 'Unauthorized', msg: 'Access is denied due to invalid credentials.' },
                { type: '403', title: 'User Not Found', msg: 'Access is denied due to invalid user.' }
               

            ],
            msg: '',
            type: '',
            title: ''
        }
    },
    methods:{
        setError(err){
            for(var i = 0; i < this.error.length; i++){
                if(this.error[i].type === err){
                    this.msg = this.error[i].msg;
                    this.type = this.error[i].type;
                    this.title = this.error[i].title;
                }
            }
        }
    },
    mounted(){
        this.setError(this.$route.params.id);
    }
}
</script>

<style lang="scss">
    .font-xxxl{
        font-size: 50px;
        line-height: 50px;
    }
</style>
