<template>
    <div class="media">
        <div class="media-profile avatar avatar-lg">
            <img src="@/assets/img/user.png" alt="">
        </div>
        <div class="media-body">
            <div class="contact-name">
                <h5>{{ name }}</h5>
                <h6>{{ dateTime | moment("ddd, D MMM, hh:mm A") }}</h6>
                <ul class="msg-box">
                    <li v-if="conversations" class="msg-setting-main">
                        <div class="msg-dropdown-main">
                            <div class="msg-setting"><feather type="more-horizontal" size="15"></feather></div>
                            <div class="msg-dropdown"> 
                            <ul>
                                <li><a href="#"><feather type="share-2" size="15"></feather>forward</a></li>
                                <li><a href="#"><feather type="copy" size="15"></feather>copy</a></li>
                                <li><a href="#"><feather type="star" size="15"></feather>rating</a></li>
                                <li><a href="#"><feather type="trash-2" size="15"></feather>delete</a></li>
                            </ul>
                            </div>
                        </div>
                        <h5 v-html="checkIfURL(conversations)"></h5>
                    </li>
                    <li v-if="thumbnail1" class="msg-setting-main">
                        <div class="msg-dropdown-main">
                            <div class="msg-setting"><feather type="more-horizontal" size="15"></feather></div>
                            <div class="msg-dropdown"> 
                            <ul>
                                <li><a href="#"><feather type="share-2" size="15"></feather>forward</a></li>
                                <li><a href="#"><feather type="copy" size="15"></feather>copy</a></li>
                                <li><a href="#"><feather type="star" size="15"></feather>rating</a></li>
                                <li><a href="#"><feather type="trash-2" size="15"></feather>delete</a></li>
                            </ul>
                            </div>
                        </div>
                        <img :src="thumbnail1" alt="" />
                    </li>
                    <li v-if="thumbnail2" class="msg-setting-main">
                        <div class="msg-dropdown-main">
                            <div class="msg-setting"><feather type="more-horizontal" size="15"></feather></div>
                            <div class="msg-dropdown"> 
                            <ul>
                                <li><a href="#"><feather type="share-2" size="15"></feather>forward</a></li>
                                <li><a href="#"><feather type="copy" size="15"></feather>copy</a></li>
                                <li><a href="#"><feather type="star" size="15"></feather>rating</a></li>
                                <li><a href="#"><feather type="trash-2" size="15"></feather>delete</a></li>
                            </ul>
                            </div>
                        </div>
                        <img :src="thumbnail2" alt="" />
                    </li>
                    <li v-if="vcard" class="msg-setting-main">
                        <div class="msg-dropdown-main">
                            <div class="msg-setting"><feather type="more-horizontal" size="15"></feather></div>
                            <div class="msg-dropdown"> 
                            <ul>
                                <li><a href="#"><feather type="share-2" size="15"></feather>forward</a></li>
                                <li><a href="#"><feather type="copy" size="15"></feather>copy</a></li>
                                <li><a href="#"><feather type="star" size="15"></feather>rating</a></li>
                                <li><a href="#"><feather type="trash-2" size="15"></feather>delete</a></li>
                            </ul>
                            </div>
                        </div>
                        <div class="chat-box chat-box-vcf">
                            <div class="chat-box-profile">
                                {{ nameInitials }}
                            </div>
                            <div class="chat-box-details">
                                <h5>{{ vcardName }}</h5>
                                <!-- <h6>{{ msg }}</h6> -->
                            </div>
                            <div class="chat-box-date-status">
                                <a :href="vcard" class="sidebar-main-icon btn-icon btn-gray-100">
                                    <feather type="download" size="15"></feather>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
const linkifyHtml = require("linkifyjs/html");
export default {
    props:{
        avatar:{
            type: String
        },
        thumbnail1:{
            type: String
        },
        thumbnail2:{
            type: String
        },
        vcard:{
            type: String
        },
        name:{
            type: String
        },
        dateTime:{
            type: String
        },
        conversations:{
            type: String
        }
    },
    data(){
        return{
            vcardName: "",
            nameInitials: "",
        }
    },
    methods:{
        checkIfURL(textToCheck) {
            return linkifyHtml(textToCheck, {
                defaultProtocol: "http",
            });
        },
        getInitials(nameString) {
            const fullName = nameString.split(" ");
            const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
            return initials.toUpperCase();
        },
        getVCFdetails(){
            const repName = localStorage.getItem("name");
            this.nameInitials = this.getInitials(repName);
            this.vcardName = repName;
        }
    },
    mounted(){
        this.getVCFdetails();
    }
}
</script>

<style lang="scss">
    .linkified {
        text-decoration: underline;
        color: #0075f6;
    }
</style>