<template>
  <div class="px-2">
    <ul class="tabs">
      <li >
          <b-link :to="{name: 'chatHistory'}" router-link-active="active" class="tabs-item">
            <feather type="message-square" size="15"></feather>
            Chat
           </b-link>
        
      </li>
       <li >
           <b-link :to="{name: 'callHistory'}" router-link-active="active" class="tabs-item">
            <feather type="phone" size="15"></feather>
            Call Log 
           </b-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>