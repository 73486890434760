<template>
  <div class="sidebar-default">
    <div class="sidebar-main">
      <div class="sidebar-main-header">
        <div class="sidebar-main-title">
          <img class="sidebar-main-title-img" src="@/assets/img/logo.png" alt="" />
        </div>
        <button
          type="button"
          class="sidebar-main-icon btn-icon btn-primary"
          @click="toggleMenuFunc()"
        >
          <feather :type="[!toggleMenu ? 'menu' : 'x']" size="18"></feather>
        </button>
      </div>

      <div class="sidebar-main-content mt-2">
        <HomeNavbar></HomeNavbar> 
      </div>
    </div>
    <div class="sidebar-body"  @click="closeMenuFunc" :class="{overflowHidden: chatTab === 3}">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
//import HomeView from "@/components/Home/Home.vue";
import HomeNavbar  from "@/components/navbar/Navbar.vue"

export default {
  data() {
    return {
      toggleMenu: false,
      settings: {
        maxScrollbarLength: 60,
      },
      tabs: [
        {
          id: 0,
          icon: "home",
          title: "Home ",
        },
        {
          id: 1,
          icon: "users",
          title: "Contacts",
        },
        {
          id: 2,
          icon: "phone",
          title: "Chats",
        },
      ],
      chatTab: 0
     
    };
  },
  created() {
      this.$store.commit('initialize');
  },
  methods: {
    toggleMenuFunc(){
    
      this.toggleMenu = !this.toggleMenu;
      this.$emit('clicked', this.toggleMenu);
    },
    closeMenuFunc(){
      if(this.toggleMenu){
        this.toggleMenu = !this.toggleMenu;
        this.$emit('clicked', this.toggleMenu);
      }
    },
    autoAdjustHeight(){
      setTimeout(() => {
        var header = document.getElementsByClassName('sidebar-main')[0].clientHeight,
            footer = document.getElementsByClassName('sidebar-footer')[0].clientHeight,
            screenHeight = window.innerHeight,
            scrollheight;
            
        if(document.getElementsByClassName('search-box').length > 0){
          var search = document.getElementsByClassName('search-box')[0].clientHeight;
          scrollheight = (screenHeight - header - search - footer) + 'px';
        }else{
          scrollheight = (screenHeight - header - footer) + 'px';
        }
        document.getElementsByClassName('scrollbox')[0].style.height = scrollheight;
      }, 1000);
    }
  },
  mounted() {
    // this.autoAdjustHeight();
  },
  /*eslint-disable */
  // watch:{
  //   $route (to, from){
  //       this.autoAdjustHeight();
  //     }
  // },
  /*eslint-enable */
  components: {   
    HomeNavbar
  },
};
</script>

<style lang="scss">
  .overflowHidden{
    overflow: hidden !important;
  }
</style>
